<div id="conatainer_endStroke">
    <div class="row md-tab-center">
        <div class="col-4 flex-justify-center"style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            EXPECTED CASE
        </div>
        <div class="col-4 flex-justify-center" style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            WORST CASE
        </div>
        <div class="col-4 flex-justify-center"style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
            BEST CASE
        </div>
    </div>
    <table mat-table [dataSource]="dataSourceMWB_second" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;">{{this.durataBP.annoStart}} </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlc > element.annoPrec ? 'fa-arrow-up verde': element.mlc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcMedioTerm > element.mlc ? 'fa-arrow-up verde': element.mlcMedioTerm < element.mlc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc" styLe="text-align: end !important;">LT</th>
            <td mat-cell *matCellDef="let element"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-arrow-up verde': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;">{{this.durataBP.annoStart}}  </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wc > element.annoPrec ? 'fa-arrow-up verde': element.wc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcMedioTerm > element.wc? 'fa-arrow-up verde': element.wcMedioTerm < element.wc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc" styLe="text-align: end !important;">LT </th>
            <td mat-cell *matCellDef="let element"> {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-arrow-up verde': element.wcLungoTerm < element.wcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;"> {{this.durataBP.annoStart}} </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
        <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
        <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
            class="fa {{element.bc > element.annoPrec ? 'fa-arrow-up verde': element.bc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
    </ng-container>

        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;"> MT </th>
            <td mat-cell *matCellDef="let element"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend" > </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcMedioTerm > element.bc? 'fa-arrow-up verde': element.bcMedioTerm < element.bc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc" styLe="text-align: end !important;">LT </th>
            <td mat-cell *matCellDef="let element"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-arrow-up verde': element.bcLungoTerm < element.bcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <table mat-table [dataSource]="dataSourceMWB_thirt" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc" style="color: transparent !important">.</th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlc > element.annoPrec ? 'fa-arrow-up verde': element.mlc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcMedioTerm > element.mlc ? 'fa-arrow-up verde': element.mlcMedioTerm < element.mlc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-arrow-up verde': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc">  </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wc > element.annoPrec ? 'fa-arrow-up verde': element.wc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcMedioTerm > element.wc? 'fa-arrow-up verde': element.wcMedioTerm < element.wc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-arrow-up verde': element.wcLungoTerm < element.wcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc">  </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bc > element.annoPrec ? 'fa-arrow-up verde': element.bc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>
    
        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcMedioTerm > element.bc? 'fa-arrow-up verde': element.bcMedioTerm < element.bc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-arrow-up verde': element.bcLungoTerm < element.bcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <table mat-table [dataSource]="dataSourceMWB_fourt" class="mat-elevation-z8 md-tab-center ">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc"style="color: transparent !important">. </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCYTD">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlc > element.annoPrec ? 'fa-arrow-up verde': element.mlc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcMedioTerm != 0 ? (element.mlcMedioTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCMT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcMedioTerm > element.mlc ? 'fa-arrow-up verde': element.mlcMedioTerm < element.mlc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcLungoTerm != 0 ? (element.mlcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_MLCLT">
            <th mat-header-cell *matHeaderCellDef class="mlc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.mlcLungoTerm > element.mlcMedioTerm ? 'fa-arrow-up verde': element.mlcLungoTerm < element.mlcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc">  </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCYTD">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wc > element.annoPrec ? 'fa-arrow-up verde': element.wc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.wcMedioTerm != 0 ? (element.wcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCMT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcMedioTerm > element.wc? 'fa-arrow-up verde': element.wcMedioTerm < element.wc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.wcLungoTerm != 0 ? (element.wcLungoTerm | number:'1.0-0':'it') :
                '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="TR_WCLT">
            <th mat-header-cell *matHeaderCellDef class="wc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.wcLungoTerm > element.wcMedioTerm? 'fa-arrow-up verde': element.wcLungoTerm < element.wcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc">  </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCYTD">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bc > element.annoPrec ? 'fa-arrow-up verde': element.bc < element.annoPrec ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        
        <ng-container matColumnDef="BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.bcMedioTerm != 0 ? (element.bcMedioTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCMT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcMedioTerm > element.bc? 'fa-arrow-up verde': element.bcMedioTerm < element.bc ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <ng-container matColumnDef="BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc"> </th>
            <td mat-cell *matCellDef="let element"> {{element.bcLungoTerm != 0 ? (element.bcLungoTerm | number:'1.0-0':'it') :
                '-'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="TR_BCLT">
            <th mat-header-cell *matHeaderCellDef class="bc trend"> </th>
            <td mat-cell *matCellDef="let element" class="trend"> <i style="font-size:10px"
                class="fa {{element.bcLungoTerm > element.bcMedioTerm? 'fa-arrow-up verde': element.bcLungoTerm < element.bcMedioTerm ? 'fa-arrow-down rosso': 'fa-arrows-h blu'}}"></i> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
</div>
