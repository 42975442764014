import { SintesiTopSection } from '../interface/sintesi-top-section';
import { SplashpageService } from '../services/splashpage.service';
import { TimelineButtonsNavigatorService } from '../services/timeline-buttons-navigator.service';

import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormsModule } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { delay } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { PraticheService } from 'src/app/_services/pratiche.service';
import { LoadingService } from 'src/app/loading.service';
import { DatiQuestionarioService } from '../../_services/dati-questionario.service';

import { ValoriTabellaSemplice } from 'src/app/valori-tabella-semplice';
import { ValoriTabellaWithOption } from 'src/app/interface/tabelle/valori-tabella-with-option';
import { ValoriCellaDSRCDebitoria } from 'src/app/interface/tabelle/valori-cella-dsrc-debitoria';

import { faCaretUp, faCaretDown, faInfo, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { Subject, Subscription } from 'rxjs';
import { TabellaSempliceComponent } from '../tabelle/tabella-semplice/tabella-semplice.component';
import { InterazioneRisultatiQuestionarioService } from 'src/app/interazione-risultati-questionario.service';
import { MenuPulsantieraService } from 'src/app/header/services/menu-pulsantiera.service';
import { SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DettaglioRisultatiService } from 'src/app/_services/dettaglio-risultati.service';
import { ColCompl, saveDettaglio } from '../interface/dettaglio';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { SoglieCrisiImpresa } from './../interface/SoglieCrisiImpresa';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { Pratiche } from 'src/app/component/pratiche';
import { RichiedenteService } from 'src/app/_services/richiedente.service';
import { ChartType, ChartData, ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';


enum PositionColum {
  Prima = 0,
  Seconda,
  Terza,
  Quarta,
  Quinta
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

@Component({
  selector: 'app-dettaglio-risultati',
  templateUrl: './dettaglio-risultati.component.html',
  template: '',
  styleUrls: ['./dettaglio-risultati.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class DettaglioRisultatiComponent implements OnInit, OnDestroy {
  @Input() currentBp: PraticheNuova;
  @Input() smartBp: boolean;
  risultatiVolatileSubscription: Subscription
  commentoSubscription: Subscription
  inputColumns = [];
  displayColumns: string[];
  displayData: any[];
  inputData = [];
  codiceAteco: string = ''

  boolSeconSectionIndiciBilancio = false;
  successColor = '#28a745';
  warningColor = '#ffc107';
  dangerColor = '#dc3545';
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faInfo = faInfo;
  submitted = false;
  boolAziendaEsistente = false;
  //boolRichiedente = false;
  //boolProgetto = false;
  //boolIndebitamento = false;
  //boolGaranzie = false;
  //boolInput = false;
  boolRicavi = false;
  boolCosti = false;
  boolCostiProg = false;
  boolNetProfit = false;
  boolRicaviProgetto = false;
  boolCostiProgetto = false;
  boolEbitda = false;
  boolNetProfitProgetto = false;
  boolEquityProgetto = false;
  //boolProject = false;
  boolEbit = false;
  boolCr = false;
  boolAutoLiq = false;
  boolRev = false;
  boolScad = false;
  //boolCE = false;
  //boolCF = false;
  //boolSP = false;
  //boolDeb = false;
  //boolAttCirc = false;
  //boolMccProsp = false;
  //boolIndiceBil = false;
  coloreProgetto = '#92a8d1';
  coloreIndebitamento = '#92a8d1';
  coloreGaranzie = '#92a8d1';
  annoCorrente = (new Date()).getFullYear() + 1;
  isLoading: boolean = false;
  formQuestionario: UntypedFormGroup;
  indicatoreEconometrico = 1;
  tipoDebito = 'Totale';
  range: string[] = [];
  rangeDebito: string[] = [];
  frequenze = null;
  maxFrequenze = 0;
  minimoIndicatore = 0;
  massimoIndicatore = 0;
  mediaIndicatore = 0;
  anniBP = 0;
  anniCE = 0;
  anniCF = 0;
  anniSP = 0;
  anniDeb = 0;
  profiloBP = 1;
  meseCr = 0;
  anniDebito = 0;
  valDeb = 0;
  latenzaMax: number = 0;
  idRegioneProgettoArray: number[] = [];
  idProvinciaProgettoArray: number[] = [];
  idTerritorioProgettoArray: number[] = [];
  idCanaleDistribuzioneArray: number[] = [];
  idCategoriaArray: number[] = [];
  idColturaProgettoArray: number[] = [];
  idVarietaProgettoArray: number[] = [];
  finanziamentoSplitArray: number[] = [];
  fgBioProgettoArray: boolean[] = [];
  etaPianteArray: number[] = [];
  ettariProgettoArray: number[] = [];
  affittoArray: number[] = [];
  idQualitaProduzioneArray: number[] = [];
  coloreIndicatore = '';
  coloreDebito = '';
  totaleInvestimento = 0;
  crPresente = false;
  bilancioPresente = false;
  valutazioneCr: any = {};
  valutazioneBilancio: any = {};
  valutazioneMcc: any = {};
  arrayDateCr: Date[] = [];
  mesiSconfAutoLiq = 0;
  mesiSconfScad = 0;
  mesiSconfRev = 0;
  semaforoMcc = 0;
  nomeSalvataggio = '';
  idBusinessPlan = 0;
  riferimentoOld = '';
  msg = '';
  msgRichiedente = '';
  msgProgetto = '';
  msgGaranzie = '';
  definitivo = false;
  checkInvestimenti = true;
  checkSommarioTabelle = true;
  checkTabelle = true;
  checkTutti = true;
  isPersistent = false;
  fileUpload: any = {};

  valutazioneMccProsp = 8;
  frequenzeMcc = null;
  prefissoMcc = '';
  anniMcc = 0;
  anniIndice = 0;
  profiloIndice = 2;
  freqDscrAnnuo = null;
  indiceDscrAnnuo = 6;
  anniDscrAnnuo = 0;
  indiceBil = 11;
  freqIndiceBil = null;
  bordiIndiceBil = [0];
  probabilitaMeglio = [99.9, 75, 50, 25, 0.1];
  probabilitaPeggio = [0.1, 25, 50, 75, 99.9];
  risultatiQuestionario: any = {
    ricaviTot: [0, 0, 0, 0, 0], costiComplessiviTot: [0, 0, 0, 0, 0], ebitdaTot: [0, 0, 0, 0, 0], ebitTot: [0, 0, 0, 0, 0], netProfitTot: [0, 0, 0, 0, 0], cashFlowEqTot: [0, 0, 0, 0, 0], cashFlowPrTot: [0, 0, 0, 0, 0],
    valAzienda: 0, valProgMed: 0, valDebMed: 0, valGaranzia: 0, valDscrFrontEnd: [0, 0, 0, 0, 0], dscrFrontEndTot: [0, 0, 0, 0, 0], numSimulazioni: 1000, irrEqFrontEnd: [0, 0, 0, 0, 0], irrPrFrontEnd: [0, 0, 0, 0, 0], breakEvenFrontEnd: [0, 0, 0, 0, 0], valCompanyFrontEnd: [0, 0, 0, 0, 0],
    dscrFrontEnd: [0, 0, 0, 0, 0], annoDefaultFrontEnd: [-1, -1, -1, -1, -1]
  };
  caricato = false;
  faAngleDoubleDown = faAngleDoubleDown;
  bordiDscr = [0];
  bordiMcc = [0];
  ambitoAssunzioni = 1;

  active = 'top';

  show_table: string = 'Riepilogo';


  foscusCol: number;

  dettaglioVolatile: saveDettaglio;
  ELEMENT_COMPLESSIVA: ColCompl[] = [];
  dataSourceComplessiva: ColCompl[];
  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;

  name_potenziometro = ["DSCR", "Debitoria", "Rischi", "Garanzie"]
  potenziometro = ["principale", "debitoria", "mcc", "garanzia"]
  lancetta = ["lancetta-principale", "lancetta-debitoria", "lancetta-mcc", "lancetta-garanzia"]

  commentoExp: string = '';

  styleTabellaProfiloProspettico: string[] = ["col-12 nopadding margin-top-10",
    "row row-cols-5 margin-0 text-center",
    "col-12  font-bold custom-simple-tab-header",
    "col-12  custom-simple-tab-content"]

  styleTabellaMCC: string[] = ["col-12 nopadding mx-auto",
    "row row-cols-5 margin-0 text-center",
    "col-12 min-height-25 margin-top-10 font-bold custom-simple-tab-header-mcc",
    "col-12 min-height-25 custom-simple-tab-content"]

  styleTabellaCentraleRischi: string[] = ["col-12 nopadding mx-auto",
    "row row-cols-5 margin-0 text-center",
    "col min-height-25 margin-top-10 font-bold",
    "col min-height-25"]

  styleTabellaGaranzie: string[] = ["col-12",
    "row margin-0 text-center nopadding",
    "col-2 min-height-25 margin-top-10 font-bold custom-simple-tab-header-granzie",
    "col-2 min-height-25 custom-simple-tab-content-garanzie"]


  styleTabellaDSRC: string[] = ["row margin-0 text-center nopadding",
    "col-4 min-height-25 font-bold",
    "col-4 min-height-25 focusPotenziometro"];

  styleTabellaIndiicatoriEconomici: string[] = ["row margin-0 text-center nopadding",
    "col-4  min-height-25 font-bold",
    "col-4   min-height-25"];

  valoriTabellaProfiloProspettico: ValoriTabellaSemplice;
  valoriTabellaMCC: ValoriTabellaSemplice;
  valoriTabellaCentraleRischi: ValoriTabellaSemplice;
  valoriTabellaGaranzie: ValoriTabellaSemplice;

  valoriTabellaDSRC: ValoriTabellaWithOption;
  valoriTabellaIndiicatoriEconomici: ValoriTabellaWithOption;

  eventFocusDSCR: Subject<void> = new Subject<void>();
  tableDSRCsemplice: any;

  subscriptionDSRC: Subscription;

  subscriptiontfocusYear: Subscription;

  subscriptiontShiftLeftYear: Subscription;

  subscriptiontShiftRightYear: Subscription;
  annoStartSubscription: Subscription;
  topSectionSubscription: Subscription;
  risultatiDettaglioSubscription: Subscription;
  annoBilancioSubscription: Subscription;


  ELEMENT_AS_IS_QUADROSINTETICO: SintesiTopSection[] = [
    { tipoQuadroSintetico: "DSCR", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "PFN/EBITDA", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "LEVERAGE", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "EBITDA MARGIN", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "MCC (% INADEMPIMENTO)", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "ROE", ytd: '', plusone_yd: '', mt: '', lt: '' },
  ];

  displayedColumns: string[] = ['Quadro sintetico', 'YTD', '+1YD', 'MT', 'LT'];
  dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
  sommaColonneCrisi = [];
  soglieCrisi: SoglieCrisiImpresa;
  valoreMax: any = { dscr: 3 };
  anniFE = 0;
  annoInizioBilancio = 0;
  idRuoloUtente = 0;
  listaProfiliProspettici = [];
  boolCrisiOld = false;
  boolLdbConto = false;
  datiMercato = {
    ateco: "",
    ricavi: [0,0,0,0,0,0],
    costi: [0,0,0,0,0,0],
    ebitda: [0,0,0,0,0,0],
  };
  anniOutput = 0;
//----------- GRAFICI ---------------//
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartType: ChartType = 'bar';
  barChartDataAutoliq: ChartData<'bar'>;
  barChartDataScad: ChartData<'bar'>;
  barChartDataRev: ChartData<'bar'>;
  barChartDataAutoliqMean: ChartData<'bar'>;
  barChartDataScadMean: ChartData<'bar'>;
  barChartDataRevMean: ChartData<'bar'>;

  barChartDataAutoliqInit: ChartData<'bar'>[] = [];
  barChartDataScadInit: ChartData<'bar'>[] = [];
  barChartDataRevInit: ChartData<'bar'>[] = [];
  barChartDataAutoliqMt: ChartData<'bar'>[] = [];
  barChartDataScadMt: ChartData<'bar'>[] = [];
  barChartDataRevMt: ChartData<'bar'>[] = [];
  barChartDataAutoliqFine: ChartData<'bar'>[] = [];
  barChartDataScadFine: ChartData<'bar'>[] = [];
  barChartDataRevFine: ChartData<'bar'>[] = [];

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip:{
        callbacks:{
          
          label: function(context) {
            let label = context.dataset.label || '';
            if (context.parsed.y !== null ) {
              let currentValue:number =  context.parsed.y;
              label = new Intl.NumberFormat('it-IT').format(currentValue);
            }
            return context.chart.data.datasets[context.datasetIndex].label +  ": " + label;
        },

          title: function(tooltipItems) {
            return ''
            }

      }
    }
    
    }
  };
//------------------------------------//
  constructor(
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private timelineButtonsNavigatorService: TimelineButtonsNavigatorService, private splashpageService: SplashpageService, private httpClient: HttpClient, private datiQuestionarioService: DatiQuestionarioService,
    private router: Router, private formBuilder: UntypedFormBuilder, private loading: LoadingService,
    private decimalPipe: DecimalPipe, private tokenStorageService: TokenStorageService, private modalService: NgbModal,
    private datePipe: DatePipe,
    private serviceBp: ConfigurazioneBusinessPlanService, private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService, private pulsantieraHeader: MenuPulsantieraService,
    private serviceSplashpage: SplashpageServiceService, private risultatiService: DettaglioRisultatiService,
    private richiedenteService: RichiedenteService) {
  }

  receiveShowTable(tab: string): void {
    this.show_table = tab;
    this.boolSeconSectionIndiciBilancio = false;
    this.setBackGroundDSCRtab();
    this.active = 'top';
  }

  clickFrecciaEnsioneRighe() {
    console.log("premut< freccetta");
    this.setBackGroundDSCRtab();
  }

  setBackGroundDSCRtab() {

    let nodiRigheTabDSCR = document.querySelectorAll(".row-cell");
    console.log(nodiRigheTabDSCR);
    for (let i = 0; i < nodiRigheTabDSCR.length; i++) {
      if (i % 2 != 0) {
        console.log("odd");
        setTimeout(() => {
          nodiRigheTabDSCR[i].setAttribute("class", "row row-cell  background-color-oddRow")
        }, 100);



      }
      else {
        setTimeout(() => {
          nodiRigheTabDSCR[i].setAttribute("class", "row row-cell background-color-evenRow")
        }, 100);
      }
    }

  }


  onChangeCommento() {
    this.risultatiService.aggiornaCommento(this.commentoExp);
  }

  setBackGroundDSCRtabTimeout() {
    setTimeout(() => {
      let nodiRigheTabDSCR = document.querySelectorAll(".row-cell");
      console.log(nodiRigheTabDSCR);
      for (let i = 0; i < nodiRigheTabDSCR.length; i++) {
        if (i % 2 != 0) {
          console.log("odd");
          setTimeout(() => {
            nodiRigheTabDSCR[i].setAttribute("class", "row row-cell  background-color-oddRow")
          }, 100);



        }
        else {
          setTimeout(() => {
            nodiRigheTabDSCR[i].setAttribute("class", "row row-cell background-color-evenRow")
          }, 100);
        }
      }
    }, 200)
  }

  focusColumn(columNumber: string) {
    console.log('enter on focus col ');

    switch (columNumber) {
      case 'first_year':
        this.foscusCol = PositionColum.Prima;
        break;
      case 'second_year':
        this.foscusCol = PositionColum.Seconda;
        break;
      case 'third_year':
        this.foscusCol = PositionColum.Terza;
        break;
      case 'fourth_year':
        this.foscusCol = PositionColum.Quarta;
        break;
      case 'last_year':
        this.foscusCol = PositionColum.Quinta;
        break;
    }

  }

  scrollOnDSCR(scrollEnable: boolean) {

    let offSetTop = window.document.getElementById('tab-dscr');
    let offSetTopIpad = window.document.getElementById('tab-dscr-ipad');
    let divToScroll = window.document.getElementById('prima_sezione');
    let divToScrollIpad = window.document.getElementById('prima_sezione_ipad');

    if (divToScroll != null && offSetTop != null) {
      divToScrollIpad.scrollTop = offSetTopIpad.offsetTop;
      divToScroll.scrollTop = offSetTop.offsetTop;
    }
  };

  formatInputRow(row) {
    const output = {};

    output[1] = row;

    for (let i = 1; i < this.inputData.length; ++i) {
      output[this.inputData[i].anno] = this.inputData[i][row];
    }

    return output;
  }

  ngOnInit(): void {
    this._interazioneRisultatiQuestionarioService.updateListaMenu(['Riepilogo','Indicatori Economici e Finanziari', 'Situazione Debitoria',
    'Conto Economico', 'Cash Flow',
    'Stato Patrimoniale', 'Debito Finanziario', 'Valutazione MCC Prospettica',
    'Scenari di probabilità', 'KPI', 'Valutazione Crisi di Impresa',]);
    
    this.idRuoloUtente = this.tokenStorageService.getUser().roles.id;
    this.idBusinessPlan = this.currentBp.id;
    if (this.idBusinessPlan != 0) {
      this.risultatiService.getSoglieCrisiImpresa(this.idBusinessPlan).subscribe(soglie => {
        this.soglieCrisi = soglie;
      })
     
      this.richiedenteService.getCodiceAtecoPrimario(this.currentBp.idAziendaRichiedente).subscribe((ateco: string) => {
        this.codiceAteco = ateco
      })
    }
    this.commentoSubscription = this.risultatiService.risultatiCommento$.subscribe(comm => {
      if (comm != null) this.commentoExp = comm;
    })

    this.annoStartSubscription = this.risultatiService.annoStart$.subscribe(annoStrat => {
      this.annoCorrente = annoStrat;
      if(this.currentBp && this.currentBp.idAziendaRichiedente){
        this.risultatiService.getContestoMercato(annoStrat,this.currentBp.idAziendaRichiedente,this.currentBp.dataCreazione).subscribe(response => {
          if (response != null){ 
            this.datiMercato = response;
          }
        });
      }

    })



    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    /*this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {

      this.isPersistent = !isVolatile;

    })*/



    if (this.isPersistent) {
      //TODO SELECT DETTAGLIO NEL CASO DI DATI PERSISTENTI
if(this.idBusinessPlan != 0){
      this.serviceSplashpage.postGetAnnoBilancio(this.idBusinessPlan).subscribe(startInizioBilancio => {
        if (startInizioBilancio != null) {
          this.annoInizioBilancio = startInizioBilancio;
        }
      });

      this.splashpageService.postAsIsData(this.idBusinessPlan).subscribe(sintesiTopSection => {

        let valoriTopSection = sintesiTopSection;

        this.ELEMENT_AS_IS_QUADROSINTETICO = [];
        for (let i = 0; i < valoriTopSection.topTable.rtt.length; i++) {
          this.ELEMENT_AS_IS_QUADROSINTETICO.push({
            tipoQuadroSintetico: valoriTopSection.topTable.rtt[i].tipoQuadroSintetico,
            ytd: valoriTopSection.topTable.rtt[i].ytdString,
            plusone_yd: valoriTopSection.topTable.rtt[i].plusone_ydString,
            mt: valoriTopSection.topTable.rtt[i].mtString,
            lt: valoriTopSection.topTable.rtt[i].ltString
          });
        }

        this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
          valMax => {
            valMax.forEach(element => {
              if (element.error == null) {
                this.valoreMax[element.nomeCampo] = element.valoreMax;
              }
            });
            if (valoriTopSection.topTable.rtt[0].ytd > this.valoreMax['dscr']) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '> ' + this.valoreMax['dscr'];
            }
            else if (valoriTopSection.topTable.rtt[0].ytd < this.valoreMax['dscr'] * (-1)) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '< ' + this.valoreMax['dscr'] * (-1);
            }
            if (valoriTopSection.topTable.rtt[0].plusone_yd > this.valoreMax['dscr']) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '> ' + this.valoreMax['dscr'];
            }
            else if (valoriTopSection.topTable.rtt[0].plusone_yd < this.valoreMax['dscr'] * (-1)) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
            }
            if (valoriTopSection.topTable.rtt[0].mt > this.valoreMax['dscr']) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '> ' + this.valoreMax['dscr'];
            }
            else if (valoriTopSection.topTable.rtt[0].mt < this.valoreMax['dscr'] * (-1)) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '< ' + this.valoreMax['dscr'] * (-1);
            }
            if (valoriTopSection.topTable.rtt[0].lt > this.valoreMax['dscr']) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '> ' + this.valoreMax['dscr'];
            }
            else if (valoriTopSection.topTable.rtt[0].lt < this.valoreMax['dscr'] * (-1)) {
              this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '< ' + this.valoreMax['dscr'] * (-1);
            }

            this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
          }
        );

      })

      this.dettaglioVolatile = {
        idBusinessPlan: this.idBusinessPlan,
        annoInizio: 0,
        numeroSimulazioni: 0,
        nomiLdb: [],
        complessiva: [],
        indicatori: [],
        debitoria: [],
        contoeconomico: [],
        cashflow: [],
        patrimoniale: [],
        debitoFinanziario: [],
        mcc: [],
        garanzie: null,
        indiciBilancio: [],
        istogrammaBilancio: [],
        crisiImpresa: [],
        backtesting: [],
        commento: ''
      }

      this.serviceBp.getLineeBusinessBean(this.idBusinessPlan).subscribe(listaLinee => {
        let nomiLdb = [];
        listaLinee.forEach(beanLinea => {
          nomiLdb.push(beanLinea.descrizione);
        });
        this.dettaglioVolatile.nomiLdb = nomiLdb;
      });

      this.risultatiService.complessivaTab(this.idBusinessPlan).subscribe(
        complessiva => {
          this.dettaglioVolatile.complessiva = complessiva;
          if(this.dettaglioVolatile.complessiva.length == 3){
            this.listaProfiliProspettici = ['Worst case','Expected case','Best case'];
          }else{
            this.listaProfiliProspettici = ['Molto negativo','Negativo','Medio','Positivo','Molto positivo'];
            this.profiloBP = 2;
          }
          this.RiempiTabellaProfiloProspettico();
          this.anniOutput = this.anniMinimo();
          this.caricato = true;
        }
      )

      this.risultatiService.indicatoriTab(this.idBusinessPlan).subscribe(
        indicatori => {
          this.dettaglioVolatile.indicatori = indicatori;
          this.onChangeIndicatore();
        }
      )

      this.risultatiService.debitoriaTab(this.idBusinessPlan).subscribe(
        debitoria => {
          this.dettaglioVolatile.debitoria = debitoria;
          this.onChangeIndiceDscrAnnuo();
        }
      )

      this.risultatiService.contoeconomicoTab(this.idBusinessPlan).subscribe(
        contoeconomico => {
          this.dettaglioVolatile.contoeconomico = contoeconomico;
        }
      )

      this.risultatiService.cashflowTab(this.idBusinessPlan).subscribe(
        cashflow => {
          this.dettaglioVolatile.cashflow = cashflow;
        }
      )

      this.risultatiService.patrimonialeTab(this.idBusinessPlan).subscribe(
        patrimoniale => {
          this.dettaglioVolatile.patrimoniale = patrimoniale;
        }
      )

      this.risultatiService.debitoFinanziarioTab(this.idBusinessPlan).subscribe(
        debitoria => {
          this.dettaglioVolatile.debitoFinanziario = debitoria;
        }
      )

      this.risultatiService.getCrisiImpresa(this.idBusinessPlan).subscribe(crisiImp => {
        this.dettaglioVolatile.crisiImpresa = crisiImp;
        //Workaround per i vecchi BP che non hanno worst e best case nella crisi impresa, 
        //facciamo un array con tre valori uguali
        if(crisiImp.length == 1){
          this.boolCrisiOld = true;
          this.dettaglioVolatile.crisiImpresa.push(crisiImp[0]);
          this.dettaglioVolatile.crisiImpresa.push(crisiImp[0]);
        }else{
          this.boolCrisiOld = false;

        }
        this.sommaColonneCrisi = [];

        for(let i = 0; i < crisiImp.length;i++){
          this.sommaColonneCrisi.push(this.sommaColonne(this.dettaglioVolatile.crisiImpresa[i].colonne, Object.keys(this.dettaglioVolatile.crisiImpresa[i].colonne[0])));

        }

      })

      this.risultatiService.getBacktesting(this.idBusinessPlan).subscribe(backtesting => {
        this.dettaglioVolatile.backtesting = backtesting;
      })

      this.risultatiService.garanzieTab(this.idBusinessPlan).subscribe(
        garanzie => {
          this.dettaglioVolatile.garanzie = garanzie;
          this.RiempiTabellaGaranzie();
        }
      )

      this.risultatiService.mccTab(this.idBusinessPlan).subscribe(
        mcc => {
          this.dettaglioVolatile.mcc = mcc;
          this.onChangeValutazioneMccProsp();
        }

      )

      this.risultatiService.istogrammaIndiciBilancioTab(this.idBusinessPlan).subscribe(
        istogrammaIndiciBilancio => {
          this.dettaglioVolatile.istogrammaBilancio = istogrammaIndiciBilancio;
          this.onChangeIndiceBil();
        }
      )

      this.risultatiService.indiciBilancioTab(this.idBusinessPlan).subscribe(
        indicatoribilancio => {
          this.dettaglioVolatile.indiciBilancio = indicatoribilancio;

        }
      )



    }

    } else {
      this.topSectionSubscription = this.serviceSplashpage.topSection$.subscribe(dataTopSection => {

        if (dataTopSection != null) {
          this.ELEMENT_AS_IS_QUADROSINTETICO = [
            {
              tipoQuadroSintetico: dataTopSection[0].tipoQuadroSintetico,
              ytd: dataTopSection[0].ytdString,
              plusone_yd: dataTopSection[0].plusone_ydString,
              mt: dataTopSection[0].mtString,
              lt: dataTopSection[0].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[1].tipoQuadroSintetico,
              ytd: dataTopSection[1].ytdString,
              plusone_yd: dataTopSection[1].plusone_ydString,
              mt: dataTopSection[1].mtString,
              lt: dataTopSection[1].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[2].tipoQuadroSintetico,
              ytd: dataTopSection[2].ytdString,
              plusone_yd: dataTopSection[2].plusone_ydString,
              mt: dataTopSection[2].mtString,
              lt: dataTopSection[2].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[3].tipoQuadroSintetico,
              ytd: dataTopSection[3].ytdString,
              plusone_yd: dataTopSection[3].plusone_ydString,
              mt: dataTopSection[3].mtString,
              lt: dataTopSection[3].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[4].tipoQuadroSintetico,
              ytd: dataTopSection[4].ytdString,
              plusone_yd: dataTopSection[4].plusone_ydString,
              mt: dataTopSection[4].mtString,
              lt: dataTopSection[4].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[5].tipoQuadroSintetico,
              ytd: dataTopSection[5].ytdString,
              plusone_yd: dataTopSection[5].plusone_ydString,
              mt: dataTopSection[5].mtString,
              lt: dataTopSection[5].ltString
            },

          ];

          if(dataTopSection[6].plusone_yd != 0. || dataTopSection[6].mt != 0. || dataTopSection[6].lt != 0.){
            this.ELEMENT_AS_IS_QUADROSINTETICO.push(            {
              tipoQuadroSintetico: dataTopSection[6].tipoQuadroSintetico,
              ytd: dataTopSection[6].ytdString,
              plusone_yd: dataTopSection[6].plusone_ydString,
              mt: dataTopSection[6].mtString,
              lt: dataTopSection[6].ltString
            });
          }

          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });
              if (dataTopSection[0].ytd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].plusone_yd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].plusone_yd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].mt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].mt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].lt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].lt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
            }
          );
        }
      }
      );
      if(this.smartBp){
      
      
        this.risultatiDettaglioSubscription = this.risultatiService.risultatiDettaglio$.subscribe(data => {
          if (data != null) {
            //Nel caso di smart BP mettiamo una data statica perchè serve solo per il tasso risk free (equity risk premium) che non mostriamo
            this.risultatiService.getContestoMercato(this.annoCorrente,data.idBusinessPlan,"2024-01-01").subscribe(response => {
              if (response != null){ 
                this.datiMercato = response;
              }
            });
            this.dettaglioVolatile = {
              idBusinessPlan: this.idBusinessPlan,
              annoInizio: data.annoInizio,
              numeroSimulazioni: data.numeroSimulazioni,
              complessiva: data.complessiva,
              indicatori: data.indicatori,
              debitoria: data.debitoria,
              contoeconomico: data.contoeconomico,
              cashflow: data.cashflow,
              patrimoniale: data.patrimoniale,
              debitoFinanziario: data.debitoFinanziario,
              mcc: data.mcc,
              garanzie: data.garanzie,
              indiciBilancio: data.indiciBilancio,
              istogrammaBilancio: data.istogrammaBilancio,
              crisiImpresa: data.crisiImpresa,
              commento: '',
              assunzioni: data.assunzioni
            }
            this.annoBilancioSubscription = this.serviceBp._annoBilancio$.subscribe(anno => {
              if (anno != 0) this.annoInizioBilancio = anno;
            })

            for(let i = 0; i < data.patrimoniale.length; i++){
              let dataTemp: ChartData<'bar'> = {labels: [this.annoCorrente + this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoAutoliq], label: 'Accordato', backgroundColor: '#308ef2',hoverBackgroundColor:'#308ef2'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[this.anniBP].finCastelletto)], label: 'Utilizzato', backgroundColor: '#7db1e8',hoverBackgroundColor: '#7db1e8'});
              this.barChartDataAutoliqInit.push(dataTemp);
              
              dataTemp = {labels: [this.annoCorrente + 2 +  this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoAutoliq], label: 'Accordato', backgroundColor: '#308ef2',hoverBackgroundColor:'#308ef2'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[2 + this.anniBP].finCastelletto)], label: 'Utilizzato', backgroundColor: '#7db1e8',hoverBackgroundColor: '#7db1e8'});
              this.barChartDataAutoliqMt.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente + 4 +  this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoAutoliq], label: 'Accordato', backgroundColor: '#308ef2',hoverBackgroundColor:'#308ef2'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[4 + this.anniBP].finCastelletto)], label: 'Utilizzato', backgroundColor: '#7db1e8',hoverBackgroundColor: '#7db1e8'});
              this.barChartDataAutoliqFine.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente + this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Accordato', backgroundColor: '#f7c202',hoverBackgroundColor:'#f7c202'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Utilizzato', backgroundColor: '#f7d865',hoverBackgroundColor: '#f7d865'});
              this.barChartDataScadInit.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente +2 +  this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[2 + this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Accordato', backgroundColor: '#f7c202',hoverBackgroundColor:'#f7c202'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[2 + this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Utilizzato', backgroundColor: '#f7d865',hoverBackgroundColor: '#f7d865'});
              this.barChartDataScadMt.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente + 4 +  this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[4 + this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Accordato', backgroundColor: '#f7c202',hoverBackgroundColor:'#f7c202'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[4 + this.anniBP].finPregressi + data.patrimoniale[i].colonne[4 + this.anniBP].finRateizzato)], label: 'Utilizzato', backgroundColor: '#f7d865',hoverBackgroundColor: '#f7d865'});
              this.barChartDataScadFine.push(dataTemp);
      
              dataTemp = {labels: [this.annoCorrente + this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoRevoca], label: 'Accordato', backgroundColor: '#076b13',hoverBackgroundColor:'#076b13'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[this.anniBP].finCassa + data.patrimoniale[i].colonne[this.anniBP].fabbisognoCassa)], label: 'Utilizzato', backgroundColor: '#5ea667',hoverBackgroundColor:'#5ea667'});
              this.barChartDataRevInit.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente + 2 + this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoRevoca], label: 'Accordato', backgroundColor: '#076b13',hoverBackgroundColor:'#076b13'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[2 + this.anniBP].finCassa + data.patrimoniale[i].colonne[this.anniBP].fabbisognoCassa)], label: 'Utilizzato', backgroundColor: '#5ea667',hoverBackgroundColor:'#5ea667'});
              this.barChartDataRevMt.push(dataTemp);

              dataTemp = {labels: [this.annoCorrente + 4 +  this.anniBP], datasets: []};
              dataTemp.datasets.push({data:[data.garanzie.accordatoRevoca], label: 'Accordato', backgroundColor: '#076b13',hoverBackgroundColor:'#076b13'});
              dataTemp.datasets.push({data:[Math.round(data.patrimoniale[i].colonne[4 + this.anniBP].finCassa + data.patrimoniale[i].colonne[4 + this.anniBP].fabbisognoCassa)], label: 'Utilizzato', backgroundColor: '#5ea667',hoverBackgroundColor: '#5ea667'});
              this.barChartDataRevFine.push(dataTemp);

            }
        


            if(this.dettaglioVolatile.complessiva.length == 3){
            this.listaProfiliProspettici = ['Worst case','Expected case','Best case'];
          }else{
            this.listaProfiliProspettici = ['Molto negativo','Negativo','Medio','Positivo','Molto positivo'];
            this.profiloBP = 2;
          }
            this.onChangeIndicatore();
            this.RiempiTabellaProfiloProspettico();
            this.RiempiTabellaGaranzie();
            this.onChangeIndiceDscrAnnuo();
            this.onChangeValutazioneMccProsp();
            this.onChangeIndiceBil();
            this.anniOutput = this.anniMinimo();
            this.caricato = true;
  /*
            this._interazioneRisultatiQuestionarioService.updateListaMenu(['Riepilogo', 'Indicatori Economici e Finanziari', 'Situazione Debitoria',
        'Conto Economico', 'Cash Flow', 'Stato Patrimoniale', 'Debito Finanziario', 'Valutazione MCC Prospettica', 'Scenari di probabilità', 'KPI','Assunzioni']);
  */
        this._interazioneRisultatiQuestionarioService.updateListaMenu(['Riepilogo','Indicatori Economici e Finanziari','Conto Economico', 'Cash Flow', 'Stato Patrimoniale','Scenari di probabilità', 'KPI','Probabilità di default','Valutazione MCC Prospettica','Centrale Rischi Prospettica']);
  
          }
        });
  
      }else{

      this.risultatiDettaglioSubscription = this.risultatiService.risultatiDettaglio$.subscribe(data => {
        if (data != null) {
          this.dettaglioVolatile = {
            idBusinessPlan: this.idBusinessPlan,
            annoInizio: data.annoInizio,
            numeroSimulazioni: data.numeroSimulazioni,
            nomiLdb: data.nomiLdb,
            complessiva: data.complessiva,
            indicatori: data.indicatori,
            debitoria: data.debitoria,
            contoeconomico: data.contoeconomico,
            cashflow: data.cashflow,
            patrimoniale: data.patrimoniale,
            debitoFinanziario: data.debitoFinanziario,
            mcc: data.mcc,
            garanzie: data.garanzie,
            indiciBilancio: data.indiciBilancio,
            istogrammaBilancio: data.istogrammaBilancio,
            crisiImpresa: data.crisiImpresa,
            commento: ''
          }
          this.annoBilancioSubscription = this.serviceBp._annoBilancio$.subscribe(anno => {
            if (anno != 0) this.annoInizioBilancio = anno;
          })

          

          if(this.dettaglioVolatile.complessiva.length == 3){
            this.listaProfiliProspettici = ['Worst case','Expected case','Best case'];
          }else{
            this.listaProfiliProspettici = ['Molto negativo','Negativo','Medio','Positivo','Molto positivo'];
            this.profiloBP = 2;
          }
          this.onChangeIndicatore();
          this.RiempiTabellaProfiloProspettico();
          this.RiempiTabellaGaranzie();
          this.onChangeIndiceDscrAnnuo();
          this.onChangeValutazioneMccProsp();
          this.onChangeIndiceBil();
          this.sommaColonneCrisi = [];
          for(let i = 0; i < this.dettaglioVolatile.crisiImpresa.length; i++){
            this.sommaColonneCrisi.push(this.sommaColonne(this.dettaglioVolatile.crisiImpresa[i].colonne, Object.keys(this.dettaglioVolatile.crisiImpresa[i].colonne[0])));

          }
          this.anniOutput = this.anniMinimo();
          this.caricato = true;
        }
      });
    }
    }

    //MODIFICARE, è COPIATO DA RISULTATI
    if(this.idBusinessPlan != 0 || this.smartBp){
    this.httpClient.post<any>(environment.apiUrlQuestionario + '/intermediari/analisi', { idRichiedente: this.currentBp.idAziendaRichiedente, annoMax: this.annoCorrente }).subscribe(data => {
      if (data != null) {
        this.valutazioneCr = data;
        this.mesiSconfAutoLiq = 0;
        this.mesiSconfScad = 0;
        this.mesiSconfRev = 0;
        this.arrayDateCr = [];
        for (let i = 0; i < Math.max(this.valutazioneCr.numeroMesi,12.); i++) {
          this.arrayDateCr.push(new Date(this.valutazioneCr.annoMax, this.valutazioneCr.meseMax - 1 - i, 15));
          this.valutazioneCr.sconfAutoLiq[i] > 0 ? this.mesiSconfAutoLiq++ : '';
          this.valutazioneCr.sconfScad[i] > 0 ? this.mesiSconfScad++ : '';
          this.valutazioneCr.sconfRev[i] > 0 ? this.mesiSconfRev++ : '';

        }
        let labelMese = this.datePipe.transform(this.arrayDateCr[0], 'MMMM YYYY', '', 'it');
        labelMese = labelMese[0].toUpperCase() + labelMese.slice(1);
        this.barChartDataAutoliq = {labels: [labelMese],datasets: []};
        this.barChartDataAutoliqMean = {labels: ['Media ultimi 12 mesi'],datasets: []};
        this.barChartDataScad = {labels: [labelMese],datasets: []};
        this.barChartDataScadMean = {labels: ['Media ultimi 12 mesi'],datasets: []};
        this.barChartDataRev = {labels: [labelMese],datasets: []};
        this.barChartDataRevMean = {labels: ['Media ultimi 12 mesi'],datasets: []};
       
        this.barChartDataAutoliq.datasets.push({data:[this.valutazioneCr.accOpAutoLiq[0]], label: 'Accordato', backgroundColor: '#308ef2',hoverBackgroundColor:'#308ef2'});
        this.barChartDataAutoliq.datasets.push({data:[this.valutazioneCr.utiAutoLiq[0]], label: 'Utilizzato', backgroundColor: '#7db1e8',hoverBackgroundColor: '#7db1e8'});

        this.barChartDataAutoliqMean.datasets.push({data:[this.valutazioneCr.accOpAutoLiqMedAnno], label: 'Accordato', backgroundColor: '#308ef2',hoverBackgroundColor:'#308ef2'});
        this.barChartDataAutoliqMean.datasets.push({data:[this.valutazioneCr.utiAutoLiqMedAnno], label: 'Utilizzato', backgroundColor: '#7db1e8',hoverBackgroundColor: '#7db1e8'});

        this.barChartDataScad.datasets.push({data:[this.valutazioneCr.accOpScad[0]], label: 'Accordato', backgroundColor: '#f7c202', hoverBackgroundColor:'#f7c202'});
        this.barChartDataScad.datasets.push({data:[this.valutazioneCr.utiScad[0]], label: 'Utilizzato', backgroundColor: '#f7d865',hoverBackgroundColor: '#f7d865'});

        this.barChartDataScadMean.datasets.push({data:[this.valutazioneCr.accOpScadMedAnno], label: 'Accordato', backgroundColor: '#f7c202', hoverBackgroundColor:'#f7c202'});
        this.barChartDataScadMean.datasets.push({data:[this.valutazioneCr.utiScadMedAnno], label: 'Utilizzato', backgroundColor: '#f7d865',hoverBackgroundColor: '#f7d865'});

        this.barChartDataRev.datasets.push({data:[this.valutazioneCr.accOpRev[0]], label: 'Accordato', backgroundColor: '#076b13',hoverBackgroundColor: '#076b13'});
        this.barChartDataRev.datasets.push({data:[this.valutazioneCr.utiRev[0]], label: 'Utilizzato', backgroundColor: '#5ea667',hoverBackgroundColor: '#5ea667'});

        this.barChartDataRevMean.datasets.push({data:[this.valutazioneCr.accOpRevMedAnno], label: 'Accordato', backgroundColor: '#076b13',hoverBackgroundColor: '#076b13'});
        this.barChartDataRevMean.datasets.push({data:[this.valutazioneCr.utiRevMedAnno], label: 'Utilizzato', backgroundColor: '#5ea667',hoverBackgroundColor: '#5ea667'});


        if(this.smartBp){
          this._interazioneRisultatiQuestionarioService.updateListaMenu(['Riepilogo','Indicatori Economici e Finanziari','Conto Economico', 'Cash Flow', 'Stato Patrimoniale','Scenari di probabilità', 'KPI','Probabilità di default',
            'Valutazione MCC Prospettica','Centrale Rischi Prospettica','Valutazione Centrale Rischi']);

        }else{
        this._interazioneRisultatiQuestionarioService.updateListaMenu(['Riepilogo', 'Indicatori Economici e Finanziari', 'Situazione Debitoria',
          'Conto Economico', 'Cash Flow', 'Stato Patrimoniale', 'Debito Finanziario', 'Valutazione MCC Prospettica', 'Scenari di probabilità', 'KPI', 'Valutazione Crisi di Impresa', 'Valutazione Centrale Rischi']);
        }
//'Assunzioni',
      }
    })
  }





    //richiama funzione di caricamento
    this.listenToLoading();

    this.subscriptionDSRC = this._interazioneRisultatiQuestionarioService.DSCRScroll$.subscribe(scrollEnable => {
      this.scrollOnDSCR(scrollEnable);
    })

    this.subscriptiontfocusYear = this._interazioneRisultatiQuestionarioService.yearFocus$.subscribe(columNumber => {
      this.focusColumn(columNumber);
    })

    this.subscriptiontShiftLeftYear = this._interazioneRisultatiQuestionarioService.yearLeftShift$.subscribe(shiftLeft => {
      this.anniBP = shiftLeft;
      this.anniCE = shiftLeft;
      this.anniCF = shiftLeft;
      this.anniDeb = shiftLeft;
      this.anniDebito = shiftLeft;
      this.anniDscrAnnuo = shiftLeft;
      this.anniIndice = shiftLeft;
      this.anniMcc = shiftLeft;
      this.anniOutput = Math.min(this.dettaglioVolatile.complessiva[0].colonneComplessiva.length,5);
    })

    this.subscriptiontShiftRightYear = this._interazioneRisultatiQuestionarioService.yearRightShift$.subscribe(shiftRight => {
      this.anniBP = shiftRight;
      this.anniCE = shiftRight;
      this.anniCF = shiftRight;
      this.anniDeb = shiftRight;
      this.anniDebito = shiftRight;
      this.anniDscrAnnuo = shiftRight;
      this.anniIndice = shiftRight;
      this.anniMcc = shiftRight;
      this.anniOutput = this.dettaglioVolatile.complessiva[0].colonneComplessiva.length - shiftRight;
    })

    //this.datiQuestionarioService.currentFilePresentazione.subscribe(fileUpload => this.fileUpload = fileUpload)

  }


  //START ngAfterViewChecked
  ngAfterViewChecked() {

  }

  scrollToDati() {
    // Scroll to a certain element
    document.querySelector('.dati').scrollIntoView({
      behavior: 'smooth'
    });
  }

  RiempiTabellaProfiloProspettico() {
    this.valoriTabellaProfiloProspettico = {
      rows: {
        header_titles:
          [
            "IRR equity",
            // "IRR progetto",
            // "Anno di break even",
            "Somma surplus cash flow equity",
            "DSCR totale"
          ],
        values:
          [
            {
              value: this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.irrEquity * 100,
              decoratore: "%",
              precisione: "1.2-2",
              focus: false,
              type: 'DSCR'
            },
            /*
            {
              value: this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.irrPr * 100,
              decoratore: "%",
              precisione: "1.2-2",
              focus: false,
              type: 'DSCR'
            },
            {
              value: this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.annoBE > 0 ? this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.annoBE : null,
              decoratore: this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.annoBE > 0 ? "" : 'N/A',
              precisione: "1.0-0",
              focus: false,
              type: 'DSCR'
            },
            */
            {
              value: this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.cashflowEq,
              decoratore: "€",
              precisione: "1.0-0",
              focus: false,
              type: 'DSCR'
            },
            {
              value: (this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.dscrTot > this.valoreMax['dscr']) || (this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.dscrTot < this.valoreMax['dscr'] * (-1)) ? null : this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.dscrTot,
              decoratore: (this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.dscrTot > this.valoreMax['dscr']) ? '> ' + this.valoreMax['dscr'] : (this.dettaglioVolatile.complessiva[this.profiloBP].rowEquity.dscrTot < this.valoreMax['dscr'] * (-1) ? '< ' + this.valoreMax['dscr'] * (-1) : ''),
              precisione: "1.0-2",
              focus: true,
              type: 'DSCR'
            }
          ]
      }
    }
  }

  RiempiTabellaMCC() {
    this.valoriTabellaMCC = {
      rows: {
        header_titles:
          [
            "Valutazione bilancio",
            "Valutazione CR",
            "Valutazione Integrata",
            "Probabilit&agrave; di inadempimento",
            "Rischio di credito"
          ],
        values:
          [
            {
              value: this.valutazioneBilancio.fasciaValutazione,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.valutazioneCr.fasciaValutazione,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.valutazioneMcc.valutazioneMcc,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.valutazioneMcc.probInadempimentoPerc,
              decoratore: "%",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.valutazioneMcc.rischioCredito,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            }
          ]
      }
    }
  }

  RiempiTabellaCentraleRischi() {
    this.valoriTabellaCentraleRischi = {
      rows: {
        header_titles:
          [
            "Mesi sconfino rischi autoliquidanti",
            "Mesi sconfino rischi a scadenza ",
            "Mesi sconfino rischi a scadenza ",
            "Valutazione MCC "
          ],
        values:
          [
            {
              value: this.mesiSconfAutoLiq,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.mesiSconfScad,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.mesiSconfRev,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            },
            {
              value: this.valutazioneCr.fasciaValutazione,
              decoratore: "",
              precisione: "",
              focus: false,
              type: 'MCC'
            }
          ]
      }
    }
  }

  RiempiTabellaGaranzie() {
    this.valoriTabellaGaranzie = {
      rows: {
        header_titles:
          [
            "Garanzia",
            "Probabilità di default a scadenza piano",
            "Loss given default",
            "Perdita attesa",
            "Perdita attesa annuale",
            "Indice di Sortino"
          ],
        values:
          [
            {
              value: this.dettaglioVolatile.garanzie.garanzia,
              decoratore: "€",
              precisione: "1.0-0",
              focus: false,
              type: 'garanzie'
            },
            {
              value: this.dettaglioVolatile.garanzie.probDef,
              decoratore: "%",
              precisione: "1.4-4",
              focus: false,
              type: 'garanzie'
            },
            {
              value: this.dettaglioVolatile.garanzie.lossGiven,
              decoratore: "€",
              precisione: "1.0-0",
              focus: false,
              type: 'garanzie'
            },
            {
              value: Number.isFinite(this.dettaglioVolatile.garanzie.perditaAttesa) ? this.dettaglioVolatile.garanzie.perditaAttesa : null,/*da premdere per tooltip Garanzie*/
              decoratore: Number.isFinite(this.dettaglioVolatile.garanzie.perditaAttesa) ? "%" : "N/A",
              precisione: "1.4-4",
              focus: false,
              type: 'garanzie'
            },
            {
              value: Number.isFinite(this.dettaglioVolatile.garanzie.perditaAttesaAnn) ? this.dettaglioVolatile.garanzie.perditaAttesaAnn : null,/*da premdere per tooltip Garanzie*/
              decoratore: Number.isFinite(this.dettaglioVolatile.garanzie.perditaAttesaAnn) ? "%" : "N/A",
              precisione: "1.4-4",
              focus: false,
              type: 'garanzie'
            },
            {
              value: this.dettaglioVolatile.garanzie.indiceSortino < 0 ? null : this.dettaglioVolatile.garanzie.indiceSortino,/*da premdere per tooltip Garanzie*/
              decoratore: this.dettaglioVolatile.garanzie.indiceSortino < 0 ? "N/A" : "",
              precisione: "1.2-2",
              focus: false,
              type: 'garanzie'
            }
          ]
      }
    }
  }

  /*RiempiTabellaDSRC() {
    this.valoriTabellaDSRC = {
      rows: {
        header_titles:
          [
            "Range",
            "Frequenza",
            "Probabilit&agrave;"
          ],
        values: []
      }
    }

    for (let i = 0; i < this.frequenzeDebito.length; i++) {

      let values: ValoriCellaDSRCDebitoria;

      values = {
        value: [this.rangeDebito[i], this.frequenzeDebito[i], (this.frequenzeDebito[i] / this.risultatiQuestionario.numSimulazioni) * 100],
        decoratore: ["", "", "%"],
        precisione: ["", "", "1.0-0"]
      }

      this.valoriTabellaDSRC.rows.values.push(values);
    }
  }*/

  RiempiTabellaIndicatoriEconomici() {
    this.valoriTabellaDSRC = {
      rows: {
        header_titles:
          [
            "Range",
            "Frequenza",
            "Probabilità",
            "Anno",
          ],
        values: []
      }
    }

    for (let i = 0; i < this.frequenze.length; i++) {

      let values: ValoriCellaDSRCDebitoria // questa interfaccia va bene sia per questo Riempi.. e RiempiTabellaDSRC;

      values = {
        value: [this.frequenze[i].range, this.frequenze[i].frequenza, this.frequenze[i].prob],
        decoratore: ["", "", "%"],
        precisione: ["", "", "1.0-2"]
      }

      this.valoriTabellaDSRC.rows.values.push(values);
    }
  }

  filterByMonth(array: any) {
    return array.filter((elem: any) => elem.mese == this.meseCr);
  }
  /**
  * Listen to the loadingSub property in the LoadingService class. This drives the
  * display of the loading spinner.
  */
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      }).unsubscribe();
  }

  goBack() {
    if (this.definitivo) {
      this.router.navigate(["/pratiche"]);
    } else {
      this.router.navigate(["/questionario"]);
    }
  }

  onChangeIndicatore() {

    this.frequenze = this.dettaglioVolatile.indicatori[this.indicatoreEconometrico - 1].rowIndicatori;
    this.RiempiTabellaIndicatoriEconomici();


    this.minimoIndicatore = this.dettaglioVolatile.indicatori[this.indicatoreEconometrico - 1].rowMmm.minimo;
    this.massimoIndicatore = this.dettaglioVolatile.indicatori[this.indicatoreEconometrico - 1].rowMmm.massimo;
    this.mediaIndicatore = this.dettaglioVolatile.indicatori[this.indicatoreEconometrico - 1].rowMmm.media;

  }


  onChangeValutazioneMccProsp() {
    this.frequenzeMcc = this.dettaglioVolatile.mcc[this.valutazioneMccProsp - 8].valoriSituazioneDebitoria;
    this.bordiMcc = this.dettaglioVolatile.mcc[this.valutazioneMccProsp - 8].range;

  }

  onChangeIndiceDscrAnnuo() {
    this.freqDscrAnnuo = this.dettaglioVolatile.debitoria[this.indiceDscrAnnuo - 6].valoriSituazioneDebitoria;
    this.bordiDscr = this.dettaglioVolatile.debitoria[this.indiceDscrAnnuo - 6].range;

  }

  onChangeIndiceBil() {
    this.freqIndiceBil = this.dettaglioVolatile.istogrammaBilancio[this.indiceBil - 11].valoriSituazioneDebitoria;
    this.bordiIndiceBil = this.dettaglioVolatile.istogrammaBilancio[this.indiceBil - 11].range;
  }

  sommaColonne(arrayDaSommare, keys: string[]) {
    let somma = {};
    for (let i = 0; i < keys.length; i++) {
      somma[keys[i]] = arrayDaSommare.reduce((acc, val) => { return acc + val[keys[i]] }, 0);
    }
    return somma;
  }

  tornaIndietro(): void {
    this.modalService.dismissAll();
    this.router.navigate(["/pratiche"]);
  }

  anniMinimo(){
    if(this.dettaglioVolatile && this.dettaglioVolatile.complessiva && this.dettaglioVolatile.complessiva.length > 0  && this.dettaglioVolatile.complessiva[0].colonneComplessiva){
      return Math.min(this.dettaglioVolatile.complessiva[0].colonneComplessiva.length,5);
    }else{
      return 5;

    }
  }

  isFiniteNumber(input: number){
    return input != null && !isNaN(input) && isFinite(input);
  }


  ngOnDestroy(): void {

    if (!this.isPersistent) {
      this.risultatiDettaglioSubscription.unsubscribe();
      this.topSectionSubscription.unsubscribe();
      this.annoBilancioSubscription ? this.annoBilancioSubscription.unsubscribe() : '';
    }

    this.risultatiVolatileSubscription.unsubscribe();
    this.subscriptionDSRC.unsubscribe()
    this.subscriptiontfocusYear.unsubscribe()
    this.subscriptiontShiftLeftYear.unsubscribe()
    this.subscriptiontShiftRightYear.unsubscribe()
    this.annoStartSubscription.unsubscribe()
    this.commentoSubscription.unsubscribe()
  }

}
