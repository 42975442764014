<div class="container">
    <h1 class="text-center">Smart Business Plan</h1>
    
    <div class="row" *ngIf="currentUser.roles.id == 0">
        <div class="col-2 offset-5 margin-bottom-10">
        <select id="annoSmartBp" [(ngModel)]="annoSmartBp" class="form-control" (change)="getListaRichiedenti()" >
          <!--  <option [ngValue]="2020">2020</option>
            <option [ngValue]="2021">2021</option>
            <option [ngValue]="2022">2022</option>-->
            <option [ngValue]="annoAttuale - 1">{{annoAttuale - 1}}</option>
            <option [ngValue]="annoAttuale">{{annoAttuale}}</option>
        </select>
        </div>
    </div>
    
    <table mat-table [dataSource]="dataSource" matSort matSortActive="nomeAzienda" class="mat-elevation-z8 demo-table">
        <!-- Position Column -->
        <ng-container matColumnDef="nomeAzienda">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Azienda
                osservata </th>
            <td mat-cell *matCellDef="let element"> {{element.nomeAzienda}} </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="codiceAteco">
            <th mat-header-cell *matHeaderCellDef> codice ATECO primario </th>
            <td mat-cell *matCellDef="let element"> {{element.codiceAteco}} </td>
        </ng-container>
        <ng-container matColumnDef="azioni">
            <th mat-header-cell *matHeaderCellDef> Azioni </th>
            <td mat-cell *matCellDef="let element">
                <button button type="button"
                    class="btn btn-dark margin-right-10"
                    (click)="situazioneFinanziaria(element.idAziendaRichiedente,element.nomeAzienda)">Situazione finanziaria</button>
                    <!--
                    <button button type="button"
                    class="btn btn-dark margin-right-10"
                    (click)="smartBPEvoluto(element.idAziendaRichiedente,element.nomeAzienda)">Smart BP Evoluto</button>
            -->
                    <button button type="button"
                    class="btn btn-dark margin-right-10"
                    (click)="visualizzaSmartBp(element.idAziendaRichiedente,element.nomeAzienda)">{{element.azioni[0]}}</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"
        class="mat-elevation-z8 demo-table">
    </mat-paginator>

</div>