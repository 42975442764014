import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { faTrashAlt, faInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BilancioService } from '../_services/bilancio.service';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { ToolTipService } from '../_services/tool-tip.service';
import { BeanStatico } from '../condivisi/beanStatico';
import { Investimenti, Disinvestimenti, InvestimentiDisinvestimenti } from '../interface/InvestimentiDisinvestimenti';
import { ToolTip } from '../interface/toolTip';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { SmartBpService } from '../_services/smart-bp.service';
import { Router } from '@angular/router';
import { Richiedente } from '../interface/richiedente';

@Component({
  selector: 'app-smart-bp-evoluto',
  templateUrl: './smart-bp-evoluto.component.html',
  styleUrls: ['./smart-bp-evoluto.component.css']
})
export class SmartBpEvolutoComponent implements OnInit {

  investimentiForm: UntypedFormGroup;
   investimentiDirty: boolean = false;
   disinvestimentiDirty: boolean = false;
   investimentiValid: boolean = true;
   tipiInvestimento = []
   tipiDisinvestimento = []
   listaModalitaFinanziamento: any[] = []
   listaGaranzie: any[] = []
   investimentiTempId: number[] = []
   disinvestimentiTempId: number[] = []
   tipoInv: any = {};
   investimentiOrdinati: Investimenti[] = []
   disinvestimentiOrdinati: Disinvestimenti[] = []
   liquiditaCassaBilancio: number = 0
   immobImmatBilancio: number = 0
   immobMatBilancio: number = 0
   costiPersonaleBilancio: number = 0
   costiTfrBilancio: number = 0
   costiFornitoriBilancio: number = 0
   valoreMagazBilancio: number = 0
   beneImmobilBilancio: number = 0
   ammortamentoBilancio; number = 0
 
   modalitaFin: any = {}
   trimestreInv: any = {}
   trimestreBen: any = "";
   garanzieFin: BeanStatico
   garanzieNuovoInv: BeanStatico
   tipoDisinv = {};
   isRisultatiVolatile: boolean = false
   richiedenteSmartSubscription: Subscription
   debitoFornitoriSubscription: Subscription
   trimestreList: any[] = [{ id: 1, descrizione: "1° trimestre" }, { id: 2, descrizione: "2° trimestre" }, { id: 3, descrizione: "3° trimestre" }, { id: 4, descrizione: "4° trimestre" }]
   faTrashAlt = faTrashAlt;
   faInfo = faInfo;
   faPlus = faPlus;
   richiedente: any;
   presenzaErroriInv = false;
   arrayErroriInv: boolean[] = [];
   arrayErroriLiquidita: boolean[] = []
   presenzaErroriDis = false;
   arrayErroriCostiPersonale: boolean[] = [];
   arrayErroriCostiTfr: boolean[] = [];
   arrayErroriImmobilMat: boolean[] = [];
   arrayErroriImmobilImmat: boolean[] = [];
   arrayErroriValoreMagaz: boolean[] = [];
   arrayErroriAmmortamento: boolean[] = []
   arrayErroriDebitoFornitori: boolean[] = [];
   arrayErroriImportoDis: boolean[] = [];
   submitted = false;
   listaTips: ToolTip[] = [];
   currentAnno = new Date().getFullYear()
   listaMesi: Date[] = [];
   inizioDurataSubscription: Subscription;
   constructor(
     private formBuilder: UntypedFormBuilder,
     private configurazioneBusinessPlanService: ConfigurazioneBusinessPlanService,
     private modalService: NgbModal,
     private toolTipService: ToolTipService,
     private bilancioService: BilancioService,
     private smartBpService: SmartBpService, private router: Router) { 
      this.investimentiForm = this.formBuilder.group({
        investimenti: this.formBuilder.array([]),
        disinvestimenti: this.formBuilder.array([]),
      })

     }
 
   get f() { return this.investimentiForm.controls; }
   get investimentiArray(): UntypedFormArray { return <UntypedFormArray> this.f.investimenti; }
   get investimentiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.investimentiArray.controls; };
   get disinvestimentiArray(): UntypedFormArray { return <UntypedFormArray> this.f.disinvestimenti; }
   get disinvestimentiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.disinvestimentiArray.controls; };

   latenzaRicaviArray(i: number): UntypedFormArray{
    return <UntypedFormArray> this.investimentiFormArray[i].controls.latenzaRicavi;
   }

   latenzaRicaviFormArray(i: number): UntypedFormControl[]{
    return <UntypedFormControl[]> this.latenzaRicaviArray(i).controls;
   }
 
   latenzaCostiVarArray(i: number): UntypedFormArray{
    return <UntypedFormArray> this.investimentiFormArray[i].controls.latenzaCostiVar;
   }

   latenzaCostiVarFormArray(i: number): UntypedFormControl[]{
    return <UntypedFormControl[]> this.latenzaCostiVarArray(i).controls;
   }

   latenzaCostiFissiArray(i: number): UntypedFormArray{
    return <UntypedFormArray> this.investimentiFormArray[i].controls.latenzaCostiFissi;
   }

   latenzaCostiFissiFormArray(i: number): UntypedFormControl[]{
    return <UntypedFormControl[]> this.latenzaCostiFissiArray(i).controls;
   }
   ngOnInit(): void {
     this.listaMesi = []
     for (let i = 0; i < 12; i++) {
       this.listaMesi.push(new Date(this.currentAnno, i))
     }
 
     this.richiedenteSmartSubscription = this.smartBpService.currentRichiedenteSmartBp.subscribe(richiedente => {
      if(richiedente != null && richiedente.id != 0){

      this.richiedente = richiedente;
      this.configurazioneBusinessPlanService.getListaTipoInvDis(this.richiedente.id, 'SmartBp').subscribe((response) => {
        response.tipoInvestimento.forEach(element => {
          this.tipiInvestimento.push(element)
        });
        response.tipoDisinvestimento.forEach(element => {
          this.tipiDisinvestimento.push(element)
        });

        this.configurazioneBusinessPlanService.getListaModalitaFinanziamentoEGaranzie().subscribe((lista) => {
          this.listaModalitaFinanziamento = lista.modalitaFinanziamento
          this.listaGaranzie = lista.garanzie;
         // this.getInvestimentiDisinvestimenti()

        })
      })
 


}else{
  this.router.navigate(['/smartBp'])
}
    });
   

     //this.getVociBilancioCheckInv();
 
   }
 //----SISTEMARE----------------------
 /*  getVociBilancioCheckInv() {
     if (this.bilancioPresente) {
       this.bilancioService.getVociBilancioCheckInv(this.businessPlanTemp.idStatoPatrimonialeAttivo, this.businessPlanTemp.idStatoPatrimonialePassivo, this.businessPlanTemp.idContoEconomico).subscribe(response => {
         this.liquiditaCassaBilancio = response.liquiditaCassa
         this.immobImmatBilancio = response.immobImmat
         this.immobMatBilancio = response.immobMat
         this.costiPersonaleBilancio = response.costiPersonale
         this.costiTfrBilancio = response.costiTfr
         this.costiFornitoriBilancio = response.costiFornitori
         this.valoreMagazBilancio = response.valoreMagaz
         this.ammortamentoBilancio = response.ammortamento
         // se il bilancio è abbreviato devo verificare il valore in situazione debitoria 
         if (!this.businessPlanTemp.bilancioAbbreviato) {
           this.beneImmobilBilancio = response.beneImmobil
         }
       })
     }
 
   }
   */
   getInvestimentiDisinvestimenti() {
     this.investimentiOrdinati = [];
     this.disinvestimentiOrdinati = [];
 
         this.configurazioneBusinessPlanService.getInvestimentiDisinvestimenti(this.richiedente.id).subscribe((response) => {
           this.investimentiTempId = []
           this.disinvestimentiTempId = []
           this.disinvestimentiArray.clear();
           this.investimentiArray.clear();
           this.presenzaErroriInv = false;
           this.presenzaErroriDis = false;
           this.arrayErroriInv = [];
           this.arrayErroriLiquidita = []
           this.arrayErroriImportoDis = [];

           //bisogna salvare tutti gli id degli investimenti e disinvestimenti anche nell'add nuov inv div
           if (response.investimenti != null && response.investimenti.length > 0) {
 
             this.investimentiOrdinati = this.riordinaArray(response.investimenti);
             for (let i = 0; i < this.investimentiOrdinati.length; i++) {
               this.arrayErroriInv.push(false)
               this.arrayErroriLiquidita.push(false)
               this.tipoInv = 0
               this.modalitaFin = null
               this.garanzieFin = null
               this.garanzieNuovoInv = null
               this.investimentiTempId.push(this.investimentiOrdinati[i].id)
               this.trimestreBen = "";
   
               for (let j = 0; j < this.tipiInvestimento.length; j++) {
                 if (this.investimentiOrdinati[i].idTipoInvestimento == this.tipiInvestimento[j].id) {
                   this.tipoInv = this.tipiInvestimento[j]
                 }
               }
               for (let j = 0; j < this.trimestreList.length; j++) {
                 if (this.investimentiOrdinati[i].trimestreInvestimento == this.trimestreList[j].id) {
                   this.trimestreInv = this.trimestreList[j]
                 }
                 if (this.investimentiOrdinati[i].trimestreBeneficio== this.trimestreList[j].id) {
                   this.trimestreBen = this.trimestreList[j]
                 }
               }
               for (let j = 0; j < this.listaModalitaFinanziamento.length; j++) {
                 if (this.investimentiOrdinati[i].idModalitaFinanziamento == this.listaModalitaFinanziamento[j].id) {
                   this.modalitaFin = this.listaModalitaFinanziamento[j]
                 }
               }
               for (let j = 0; j < this.listaGaranzie.length; j++) {
                 if (this.investimentiOrdinati[i].idDescrizioneGaranziaFinanziamento == this.listaGaranzie[j].id) {
                   this.garanzieFin = this.listaGaranzie[j]
                 }
               }
 
 
               this.investimentiFormArray.push(this.formBuilder.group({
                 tipoInvestimento: [{ value: this.tipoInv},  Validators.required],
                 descrizioneInvestimento: [this.investimentiOrdinati[i].descrizione == null ? { value: ''} :{ value: this.investimentiOrdinati[i].descrizione}],
                 importoInvestimento: [{ value: this.investimentiOrdinati[i].importo},  Validators.required],
                 annoInvestimento: [{ value: this.investimentiOrdinati[i].annoInvestimento == 0 ? '' : this.investimentiOrdinati[i].annoInvestimento},  Validators.required],
                 trimestreInvestimento: [{ value: this.trimestreInv}, Validators.required],
                 modalitaFinanziamento: [{ value: this.modalitaFin},  Validators.required],
                 importoFinanziamento: [{ value: this.investimentiOrdinati[i].importoFinanziamento}],
                 mesiRimborsoFinanziamento: [{ value: this.investimentiOrdinati[i].mesiRimborsoFinanziamento}],
                 preammortamentoFinanziamento: [{ value: this.investimentiOrdinati[i].preammortamentoFinanziamento}],
                 descrizioneGaranziaFinanziamento: [{ value: this.garanzieFin}],
                 importoGaranziaFinanziamento: [{ value: this.investimentiOrdinati[i].importoGaranziaFinanziamento}],
                 importoNuovoInvestitore: [{ value: this.investimentiOrdinati[i].importoNuovoInvestitore}],
                 importoLiquiditaCassa: [{ value: this.investimentiOrdinati[i].importoLiquiditaCassa}],
                 importoMezziPropri: [{ value: this.investimentiOrdinati[i].importoMezziPropri}],
                 annoBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.investimentiOrdinati[i].annoBeneficio : ''}],
                 trimestreBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.trimestreBen : ''}],
 
               }))
               this.investimentiFormArray[i].controls.modalitaFinanziamento.enable();
               this.investimentiFormArray[i].controls.tipoInvestimento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoInvestimento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.annoInvestimento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.trimestreInvestimento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.modalitaFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.preammortamentoFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
               this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
               this.investimentiFormArray[i].controls.annoBeneficio.updateValueAndValidity();
               this.investimentiFormArray[i].controls.trimestreBeneficio.updateValueAndValidity();
               this.investimentiFormArray[i].updateValueAndValidity();
 
               this.onChangeInvestimento(i)
             };
           }
           if (response.disinvestimenti != null && response.disinvestimenti.length > 0) {
             this.disinvestimentiOrdinati = this.riordinaArray(response.disinvestimenti);
             for (let i = 0; i < this.disinvestimentiOrdinati.length; i++) {
              this.arrayErroriImportoDis.push(false)

 
               this.disinvestimentiTempId.push(this.disinvestimentiOrdinati[i].id)
               for (let j = 0; j < this.tipiDisinvestimento.length; j++) {
                 if (this.disinvestimentiOrdinati[i].idTipoDisinvestimento == this.tipiDisinvestimento[j].id) {
                   this.tipoDisinv = this.tipiDisinvestimento[j]
                 }
               }
 
               this.disinvestimentiFormArray.push(this.formBuilder.group({
                 tipoDisinvestimento: [{ value: this.tipoDisinv}, Validators.required],
                 descrizioneDisinvestimento: [this.disinvestimentiOrdinati[i].descrizione == null ? { value: ''} :
                   { value: this.disinvestimentiOrdinati[i].descrizione}], //capire come impostare il required
                 importoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].importo}, Validators.required],
                 importoCassaDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].importoCassa}, Validators.required],
                 annoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].annoDisinvestimento}, Validators.required],
                 meseDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].meseDisinvestimento}, Validators.required], 
 
               }))
               this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].controls.importoDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].controls.importoCassaDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].controls.annoDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].controls.meseDisinvestimento.updateValueAndValidity();
               this.disinvestimentiFormArray[i].updateValueAndValidity();
 
               this.onChangeDisinvestimento(i);
             }
           }
         })
       
     
   }
   /* modificare. dovrà resettare la suddivisione */
   onchangeImportoInvestimento(i: number) {
     if (this.investimentiFormArray[i].controls.importoInvestimento.value != null && this.investimentiFormArray[i].controls.modalitaFinanziamento.value != null) {
       /* questo mi serve se cambia importo investimento dopo aver selezionato la modalità */
       if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3) {
         this.investimentiFormArray[i].controls.importoMezziPropri.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2) {
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1) {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
       } else {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0)
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(0)
         this.investimentiFormArray[i].controls.importoMezziPropri.setValue(0)
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(0)
         this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0)
 
       }
 
     }
     this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
     this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
     this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
     this.arrayErroriInv.splice(i, 1, false)
     this.arrayErroriLiquidita.splice(i, 1, false)
   }
 
 
   onchangeImportoDisinvestimento(i: number) {
     /* solo se seleziono vendita ramo d'azienda */
     if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value != null && this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 &&
       this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != null && this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value == 0) {
       this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.setValue(0);
       this.disinvestimentiFormArray[i].controls.importoAmmortamento.setValue(0);
 
       this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.updateValueAndValidity();
       this.disinvestimentiFormArray[i].controls.importoAmmortamento.updateValueAndValidity()
       this.arrayErroriImportoDis.splice(i, 1, false)
       
     }
   }
 
   onChangeInvestimento(i) {
     if (this.investimentiFormArray[i].controls.tipoInvestimento.value) {
       if (this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 17) {
         this.investimentiFormArray[i].controls.descrizioneInvestimento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
       } else {
         this.investimentiFormArray[i].controls.descrizioneInvestimento.clearValidators()
         this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
       }
 
     }
 
   }
 
   aggiungiInvestimento() {
     this.investimentiTempId.push(0)
     this.arrayErroriInv.push(false)
     this.arrayErroriLiquidita.push(false)
     this.investimentiDirty = true
     this.investimentiFormArray.push(this.formBuilder.group({
       tipoInvestimento: ['', Validators.required],
       descrizioneInvestimento: [''], //capire come impostare il required
       importoInvestimento: ['0', Validators.required],
       annoInvestimento: ['', Validators.required],
       trimestreInvestimento: [{ value: this.trimestreList[0] }, Validators.required],
       modalitaFinanziamento: [{ value: '', disabled: true }, Validators.required],
       importoFinanziamento: ['0'],
       mesiRimborsoFinanziamento: [''],
       preammortamentoFinanziamento: [''],
       descrizioneGaranziaFinanziamento: [''],
       importoGaranziaFinanziamento: ['0'],
       importoNuovoInvestitore: ['0'],
       importoLiquiditaCassa: ['0'],
       importoMezziPropri: ['0'],
       varRicavi: [''],
       annoBeneficioRicavi: [''],
       trimestreBeneficioRicavi: [''],
       latenzaRicavi: this.formBuilder.array([]),
       varCostiVar: [''],
       annoBeneficioCostiVar: [''],
       latenzaCostiVar: this.formBuilder.array([]),
       trimestreBeneficioCostiVar: [''],
       varCostiFissi: [''],
       annoBeneficioCostiFissi: [''],
       trimestreBeneficioCostiFissi: [''],
       latenzaCostiFissi: this.formBuilder.array([]),

     })
     )
      for (let i = 0; i < 4; i++) {
        this.latenzaRicaviArray(this.investimentiFormArray.length - 1).push(new UntypedFormControl(0));
        this.latenzaCostiVarArray(this.investimentiFormArray.length - 1).push(new UntypedFormControl(0));
        this.latenzaCostiFissiArray(this.investimentiFormArray.length - 1).push(new UntypedFormControl(0)); 
      }

     this.latenzaRicaviFormArray(this.investimentiFormArray.length - 1)[3].setValue(100); 
     this.latenzaRicaviFormArray(this.investimentiFormArray.length - 1)[3].disable(); 
     this.latenzaCostiFissiFormArray(this.investimentiFormArray.length - 1)[3].setValue(100); 
     this.latenzaCostiFissiFormArray(this.investimentiFormArray.length - 1)[3].disable(); 
     this.latenzaCostiVarFormArray(this.investimentiFormArray.length - 1)[3].setValue(100); 
     this.latenzaCostiVarFormArray(this.investimentiFormArray.length - 1)[3].disable(); 
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.tipoInvestimento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.descrizioneInvestimento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoInvestimento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.annoInvestimento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.trimestreInvestimento.updateValueAndValidity();
 
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.modalitaFinanziamento.updateValueAndValidity();
 
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.preammortamentoFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoGaranziaFinanziamento.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoNuovoInvestitore.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoLiquiditaCassa.updateValueAndValidity();
     this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoMezziPropri.updateValueAndValidity();
 
     this.investimentiFormArray[this.investimentiFormArray.length - 1].updateValueAndValidity();
     this.investimentiArray.updateValueAndValidity();
   }
 
 
   /* delirio */
   onChangeModalitaFinanziamento(i: number) {
     if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value) {
       this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(0);
       this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(0);
       this.investimentiFormArray[i].controls.importoMezziPropri.setValue(0);
       this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0);
       this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValue(0);
       this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValue(0);
       this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValue('');
       this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValue(0);
       if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators()
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators()
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12) {
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators()
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators()
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         /* pulisci finanziamento */
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10) {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         /* pulisci nuovo investitore */
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         /* pulisci finanziamento e liquidita */
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         /* pulisci finanziamento e mezzi propri */
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
 
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7) {
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
 
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6) {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5) {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
 
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4) {
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3) {
         this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoMezziPropri.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2) {
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
 
       } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1) {
         this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
         this.investimentiFormArray[i].controls.importoFinanziamento.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
 
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
       } else {
         this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
         this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
         this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
         this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
       }
       this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
       this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
       this.investimentiFormArray[i].controls.preammortamentoFinanziamento.updateValueAndValidity();
       this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
       this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.updateValueAndValidity();
       this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
       this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
       this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
     }
   }
   eliminaInvestimento(i: number) {
     this.investimentiDirty = true
     this.investimentiTempId.splice(i, 1)
     this.arrayErroriInv.splice(i, 1)
     this.arrayErroriLiquidita.splice(i, 1, false)
     this.investimentiArray.removeAt(i)
     this.investimentiArray.updateValueAndValidity()
 
   }
 
   /* FUNZIONE USATA PER DISABILITARE le voci della select */
   checkCurrentAnno(i: number, presenzaCassa: boolean) {
     /* se l'azienda è start up non ho liquidità di caasa */
     if (this.investimentiFormArray[i].controls.annoInvestimento.value != '') {
       //this.liquiditaCassaBilancio == 0 vuol dire o che non ho bilancio (start up) o che non ho liquidità a bilancio
       if ((this.liquiditaCassaBilancio == 0 || this.investimentiFormArray[i].controls.annoInvestimento.value > this.currentAnno) && presenzaCassa) {
         return true
       } else {
         return false
       }
     }
     return false
   }
 
   onChangeAnnoDisinvestimento(i: number) {
     /* if sulla cessione ramo d'azienda */
     if (this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value != '') {
       this.disinvestimentiFormArray[i].controls.annoDisinvestimento.updateValueAndValidity()
       if (this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value < this.currentAnno) {
         this.disinvestimentiFormArray[i].controls.annoDisinvestimento.setValue(this.currentAnno)
       }
     }
   }
 
   /* attiva modifica la select delle modalita finanziamento */
   onChangeAnnoInvestimento(i: number) {
     this.investimentiFormArray[i].controls.annoInvestimento.updateValueAndValidity()
     if (this.investimentiFormArray[i].controls.annoInvestimento.value != '') {
       this.investimentiFormArray[i].controls.modalitaFinanziamento.setValue('');
       if (this.investimentiFormArray[i].controls.annoInvestimento.value < this.currentAnno) {
         this.investimentiFormArray[i].controls.annoInvestimento.setValue(this.currentAnno)
       }
       this.investimentiFormArray[i].controls.modalitaFinanziamento.enable();
       /* da aggiungere la parte che prende l'anno direttamente da be */
       this.investimentiFormArray[i].controls.modalitaFinanziamento.updateValueAndValidity();
     }
     this.onChangeModalitaFinanziamento(i);
     this.checkAnno(i);
   }

   checkAnno(i : number){
    this.onChangeAnnoBeneficioRicavi(i);
    this.onChangeAnnoBeneficioCostiVar(i);
    this.onChangeAnnoBeneficioCostiFissi(i);
   }
 
   onChangeAnnoBeneficioRicavi(i: number){
     this.investimentiFormArray[i].controls.annoBeneficioRicavi.updateValueAndValidity()
     if (this.investimentiFormArray[i].controls.annoBeneficioRicavi.value != '' && this.investimentiFormArray[i].controls.annoInvestimento.value != ''  && this.investimentiFormArray[i].controls.annoBeneficioRicavi.value < this.investimentiFormArray[i].controls.annoInvestimento.value) {
         this.investimentiFormArray[i].controls.annoBeneficioRicavi.setValue(this.investimentiFormArray[i].controls.annoInvestimento.value);
       }
    this.onChangeTrimestreRicavi(i);
   }

   onChangeAnnoBeneficioCostiVar(i: number){
    this.investimentiFormArray[i].controls.annoBeneficioCostiVar.updateValueAndValidity()
    if (this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value != '' && this.investimentiFormArray[i].controls.annoInvestimento.value != ''   && this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value < this.investimentiFormArray[i].controls.annoInvestimento.value) {
        this.investimentiFormArray[i].controls.annoBeneficioCostiVar.setValue(this.investimentiFormArray[i].controls.annoInvestimento.value);
      }
    this.onChangeTrimestreCostiVar(i);

  }
  
  onChangeAnnoBeneficioCostiFissi(i: number){
    this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.updateValueAndValidity()
    if (this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value != '' && this.investimentiFormArray[i].controls.annoInvestimento.value != ''   && this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value < this.investimentiFormArray[i].controls.annoInvestimento.value) {
        this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.setValue(this.investimentiFormArray[i].controls.annoInvestimento.value);
      }
      this.onChangeTrimestreCostiFissi(i);
  }
 
   onChangeDisinvestimento(i: number) {
     /* altro */
     if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 6) {
       /* pulir tutti i validatori e gli errori della suddivisione */
       this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.clearValidators();
       /* pulir tutti i validatori e gli errori della suddivisione */
 
       this.arrayErroriImportoDis.splice(i, 1, false);
 
       /* vendita ramo d'azienda */
     } else if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5) {
 
       this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.setValidators(Validators.required);
       this.disinvestimentiFormArray[i].controls.importoAmmortamento.setValidators(Validators.required);
 
       this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.clearValidators();
     } else {
       this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.clearValidators();
       this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.clearValidators();
       this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.clearValidators();
       /* pulir tutti i validatori e gli errori della suddivisione */

       this.arrayErroriImportoDis.splice(i, 1, false);
 
     }
     this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.updateValueAndValidity();
     this.disinvestimentiFormArray[i].controls.importoAmmortamento.updateValueAndValidity();
   }
 
   aggiungiDisinvestimento() {
     this.disinvestimentiTempId.push(0)
     this.arrayErroriDebitoFornitori.push(false);
     this.arrayErroriCostiPersonale.push(false);
     this.arrayErroriCostiTfr.push(false);
     this.arrayErroriImmobilMat.push(false);
     this.arrayErroriImmobilImmat.push(false);
     this.arrayErroriImportoDis.push(false)
     this.arrayErroriValoreMagaz.push(false);
     this.arrayErroriAmmortamento.push(false);
 
     this.disinvestimentiDirty = true
     this.disinvestimentiFormArray.push(this.formBuilder.group({
       tipoDisinvestimento: ['', Validators.required],
       importoDisinvestimento: ['0', Validators.required],
       importoCassaDisinvestimento: ['0'],
       descrizioneDisinvestimento: [''],
       annoDisinvestimento: [{ value: this.currentAnno }, Validators.required],
       meseDisinvestimento: [{ value: this.trimestreList[0] }, Validators.required],
     })
     )
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.tipoDisinvestimento.updateValueAndValidity();
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.descrizioneDisinvestimento.updateValueAndValidity();
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoDisinvestimento.updateValueAndValidity();
     //this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.anniIncasso.updateValueAndValidity();
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.annoDisinvestimento.updateValueAndValidity();
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.meseDisinvestimento.updateValueAndValidity();
     this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].updateValueAndValidity();
     this.disinvestimentiArray.updateValueAndValidity();
 
   }
 
   eliminaDisinvestimento(i: number) {
     this.disinvestimentiDirty = true
     this.disinvestimentiTempId.splice(i, 1)
     this.arrayErroriImportoDis.splice(i, 1, false)
     this.disinvestimentiArray.removeAt(i)
     this.disinvestimentiArray.updateValueAndValidity()
   }
 
   Annulla() {
    this.submitted = false;

     if (this.isFormDirty()) {
       if (this.modalService.hasOpenModals) {
         this.modalService.dismissAll();
       }
       const modalRef = this.modalService.open(ModalcontentComponent, {
         backdrop: 'static',
         backdropClass: 'customBackdrop',
       });
       modalRef.componentInstance.modalPar = {
         name: "annulla",
         title: "Sicuro di voler annullare ?",
         description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
         undoButtonText: "Indietro",
         actionButtonText: "Annulla",
       }
       modalRef.result.then((r) => {
         if (r) {
           this.disinvestimentiTempId = []
           this.investimentiTempId = []
           this.investimentiForm.reset()
           this.investimentiValid = true;
           this.getInvestimentiDisinvestimenti();
           this.markFormPristine()
         }
       })
     }
   }
 
   markFormPristine() {
     this.investimentiForm.markAsPristine()
     this.investimentiDirty = false;
     this.disinvestimentiDirty = false;
   }
 
   elaboraInvestimentiDisinvestimenti() {
     if (this.investimentiValid) {
       let investimentiList: any[] = [];
      // let disinvestimentiList: Disinvestimenti[] = [];
 
       for (let i = 0; i < this.investimentiFormArray.length; i++) {

      
         investimentiList.push({
           id: this.investimentiTempId[i],
           idTipoInvestimento: this.investimentiFormArray[i].controls.tipoInvestimento.value.id,
           descrizione: this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 17 ? this.investimentiFormArray[i].controls.descrizioneInvestimento.value : '',
           importo: this.investimentiFormArray[i].controls.importoInvestimento.value != '' ? parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) : 0,
           annoInvestimento: this.investimentiFormArray[i].controls.annoInvestimento.value,
           trimestreInvestimento: this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
           idModalitaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id,
 
           importoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) : 0,
           mesiRimborsoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.value : 0,
           preammortamentoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.preammortamentoFinanziamento.value : 0,
           importoGaranziaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.value) : 0,
           idDescrizioneGaranziaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value.id : 0,
 
           importoNuovoInvestitore: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) : 0,
           importoMezziPropri: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) : 0,
           importoLiquiditaCassa: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 ||
             this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) : 0,
             varRicavi:  this.investimentiFormArray[i].controls.varRicavi.value != null && this.investimentiFormArray[i].controls.varRicavi.value != '' ? this.investimentiFormArray[i].controls.varRicavi.value : 0,
             annoBeneficioRicavi: this.investimentiFormArray[i].controls.varRicavi.value != null && this.investimentiFormArray[i].controls.varRicavi.value != '' && this.investimentiFormArray[i].controls.annoBeneficioRicavi.value ? this.investimentiFormArray[i].controls.annoBeneficioRicavi.value : this.investimentiFormArray[i].controls.annoInvestimento.value,
             trimestreBeneficioRicavi: this.investimentiFormArray[i].controls.varRicavi.value != null && this.investimentiFormArray[i].controls.varRicavi.value != '' && this.investimentiFormArray[i].controls.trimestreBeneficioRicavi.value ? this.investimentiFormArray[i].controls.trimestreBeneficioRicavi.value.id : this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
             latenzaRicavi: this.investimentiFormArray[i].controls.varRicavi.value != null && this.investimentiFormArray[i].controls.varRicavi.value != '' ? this.latenzaRicaviArray(i).getRawValue() : [100,100,100,100],
             varCostiVar:  this.investimentiFormArray[i].controls.varCostiVar.value != null && this.investimentiFormArray[i].controls.varCostiVar.value != '' ? this.investimentiFormArray[i].controls.varCostiVar.value : 0,
             annoBeneficioCostiVar: this.investimentiFormArray[i].controls.varCostiVar.value != null && this.investimentiFormArray[i].controls.varCostiVar.value != '' && this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value ? this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value : this.investimentiFormArray[i].controls.annoInvestimento.value,
             trimestreBeneficioCostiVar: this.investimentiFormArray[i].controls.varCostiVar.value != null && this.investimentiFormArray[i].controls.varCostiVar.value != '' && this.investimentiFormArray[i].controls.trimestreBeneficioCostiVar.value ? this.investimentiFormArray[i].controls.trimestreBeneficioCostiVar.value.id : this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
             latenzaCostiVar: this.investimentiFormArray[i].controls.varCostiVar.value != null && this.investimentiFormArray[i].controls.varCostiVar.value != '' ? this.latenzaCostiVarArray(i).getRawValue() : [100,100,100,100],
             varCostiFissi:  this.investimentiFormArray[i].controls.varCostiFissi.value != null && this.investimentiFormArray[i].controls.varCostiFissi.value != '' ? this.investimentiFormArray[i].controls.varCostiFissi.value : 0,
             annoBeneficioCostiFissi: this.investimentiFormArray[i].controls.varCostiFissi.value != null && this.investimentiFormArray[i].controls.varCostiFissi.value != '' && this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value ? this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value : this.investimentiFormArray[i].controls.annoInvestimento.value,
             trimestreBeneficioCostiFissi: this.investimentiFormArray[i].controls.varCostiFissi.value != null && this.investimentiFormArray[i].controls.varCostiFissi.value != '' && this.investimentiFormArray[i].controls.trimestreBeneficioCostiFissi.value ? this.investimentiFormArray[i].controls.trimestreBeneficioCostiFissi.value.id : this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
             latenzaCostiFissi: this.investimentiFormArray[i].controls.varCostiFissi.value != null && this.investimentiFormArray[i].controls.varCostiFissi.value != '' ? this.latenzaCostiFissiArray(i).getRawValue() : [100,100,100,100],
         })
       }
       /*
       for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
         disinvestimentiList.push({
           id: this.disinvestimentiTempId[i],
           idTipoDisinvestimento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id,
           importo: this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) : 0,
           descrizione: this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value ? this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value : '',
           annoDisinvestimento: this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value,
           meseDisinvestimento: this.disinvestimentiFormArray[i].controls.meseDisinvestimento.value,
           importoCassa: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 4 && this.disinvestimentiFormArray[i].controls.importoCassaDisinvestimento.value ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCassaDisinvestimento.value) : 0,
 
           importoCostiRetribuzioniPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) : 0,
           importoCostiTfrPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) : 0,
           importoDebitoFornitori: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) : 0,
           importoImmobilizzazioniMateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) : 0,
           importoImmobilizzazioniImmateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) : 0,
           importoValoreMagazzino: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value) : 0,
           importoAmmortamento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoAmmortamento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoAmmortamento.value) : 0,
         })
       }
         */
       this.richiedente.investimenti = investimentiList,
         //disinvestimenti: disinvestimentiList,
       
       //this.configurazioneBusinessPlanService.elaboraInvestimentiDisinvestimenti(request)
//ho aggiunto l'anno come anno costituzione, non è corretto, è un workaround per non creare una nuova variabile
    
    //Serve per l'id richiedente e per distinguere smart bp da bp completo
this.smartBpService.inviaRichiedenteSmartBp(this.richiedente);
//Serve per il nome in alto
this.configurazioneBusinessPlanService.inviaRichiedenteBp(this.richiedente);
this.configurazioneBusinessPlanService.isRisultatiVolatile(true);
this.router.navigate(['/preview']);     }
 
   }
 
   isFormDirty() {
     this.submitted = false;
     for (let i = 0; i < this.investimentiFormArray.length; i++) {
       this.investimentiFormArray[i].updateValueAndValidity();
       if (this.investimentiFormArray[i].dirty) {
         this.investimentiDirty = true;
       }
     }
     for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
       this.disinvestimentiFormArray[i].updateValueAndValidity();
       if (this.disinvestimentiFormArray[i].dirty) {
         this.disinvestimentiDirty = true;
       }
     }
     this.investimentiArray.updateValueAndValidity();
     this.disinvestimentiArray.updateValueAndValidity();
     this.investimentiForm.updateValueAndValidity();
     if (this.disinvestimentiDirty || this.investimentiDirty) {
       this.investimentiForm.markAsDirty()
     }
     this.submitted = this.investimentiForm.dirty
     /* }*/
     return this.submitted;
   }
 
   isFormValid() {
     this.presenzaErroriInv = false
     this.presenzaErroriDis = false;
     if (this.investimentiFormArray.length > 0) {
       for (let i = 0; i < this.investimentiFormArray.length; i++) {
         this.investimentiFormArray[i].updateValueAndValidity()
 
         this.arrayErroriInv.splice(i, 1, parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) != parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) + parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) +
           parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) + parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) ? true : false)
         /* aggiungo il check su liquidita di cassa */
         this.arrayErroriLiquidita.splice(i, 1, this.investimentiFormArray[i].controls.importoLiquiditaCassa.value > this.liquiditaCassaBilancio ? true : false)
         this.presenzaErroriInv = this.presenzaErroriInv || this.arrayErroriInv[i] || this.arrayErroriLiquidita[i];
       }
     }
     if (this.disinvestimentiFormArray.length > 0) {
       /* devo fare losplice dei controlli condizionalmente */
       for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
         if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 4) {
           /* errore sull'importo controllato con bilancio a BE. forse bisognerà agigungere altri id */
           this.arrayErroriImportoDis.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) > this.beneImmobilBilancio ? true : false)
 
         }
         this.presenzaErroriDis = this.presenzaErroriDis || this.arrayErroriImportoDis[i] || this.arrayErroriCostiPersonale[i] || this.arrayErroriDebitoFornitori[i] ||
           this.arrayErroriCostiTfr[i] || this.arrayErroriImmobilMat[i] || this.arrayErroriImmobilImmat[i] || this.arrayErroriValoreMagaz[i] || this.arrayErroriAmmortamento[i];
       }
     }
     this.investimentiArray.updateValueAndValidity();
     this.disinvestimentiArray.updateValueAndValidity();
     this.investimentiForm.updateValueAndValidity()
     this.investimentiValid = this.investimentiForm.valid && this.presenzaErroriInv == false && this.presenzaErroriDis === false
 
     return this.investimentiValid
   }
 
   onSubmit() {
     if (this.submitted) {
       //eliminare
       /* unificare il volatile */
       let investimentiList: Investimenti[] = [];
       let disinvestimentiList: Disinvestimenti[] = [];
       for (let i = 0; i < this.investimentiFormArray.length; i++) {
         let idModalitaFin = this.investimentiFormArray[i].controls.modalitaFinanziamento.value ? this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id : 0
        
         investimentiList.push({
           id: this.isRisultatiVolatile ? this.investimentiTempId[i] : 0,
           idTipoInvestimento: this.investimentiFormArray[i].controls.tipoInvestimento.value.id,
           descrizione: this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 17 ? this.investimentiFormArray[i].controls.descrizioneInvestimento.value : '',
           importo: this.investimentiFormArray[i].controls.importoInvestimento.value != '' ? parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) : 0,
           annoInvestimento: this.investimentiFormArray[i].controls.annoInvestimento.value,
           trimestreInvestimento: this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
           idModalitaFinanziamento: idModalitaFin,
 
           importoFinanziamento: idModalitaFin == 1 ||
             idModalitaFin == 5 || idModalitaFin == 6 ||
             idModalitaFin == 10 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) : 0,
           mesiRimborsoFinanziamento: idModalitaFin == 1 ||
             idModalitaFin == 5 || idModalitaFin == 6 ||
             idModalitaFin == 10 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.value : 0,
           preammortamentoFinanziamento: idModalitaFin == 1 ||
             idModalitaFin == 5 || idModalitaFin == 6 ||
             idModalitaFin == 10 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? this.investimentiFormArray[i].controls.preammortamentoFinanziamento.value : 0,
           importoGaranziaFinanziamento: idModalitaFin == 1 ||
             idModalitaFin == 5 || idModalitaFin == 6 ||
             idModalitaFin == 10 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.value) : 0,
           idDescrizioneGaranziaFinanziamento: idModalitaFin == 1 ||
             idModalitaFin == 5 || idModalitaFin == 6 ||
             idModalitaFin == 10 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? (this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value ? this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value.id : 0) : 0,
 
           importoNuovoInvestitore: idModalitaFin == 4 ||
             idModalitaFin == 8 || idModalitaFin == 9 ||
             idModalitaFin == 11 || idModalitaFin == 12 ||
             idModalitaFin == 13 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) : 0,
           importoMezziPropri: idModalitaFin == 3 ||
             idModalitaFin == 6 || idModalitaFin == 7 ||
             idModalitaFin == 9 || idModalitaFin == 10 ||
             idModalitaFin == 11 || idModalitaFin == 14 ||
             idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) : 0,
           importoLiquiditaCassa: idModalitaFin == 2 ||
             idModalitaFin == 5 || idModalitaFin == 7 ||
             idModalitaFin == 8 || idModalitaFin == 10 ||
             idModalitaFin == 11 || idModalitaFin == 13 ||
             idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) : 0,
 annoBeneficio: this.investimentiFormArray[i].controls.annoBeneficio.value ? this.investimentiFormArray[i].controls.annoBeneficio.value : 0,
 trimestreBeneficio: this.investimentiFormArray[i].controls.trimestreBeneficio.value ? this.investimentiFormArray[i].controls.trimestreBeneficio.value.id : 0,
 varRicavi: this.investimentiFormArray[i].controls.varRicavi.value,
 varCostiMaterie: this.investimentiFormArray[i].controls.varCostiVar.value,
 varCostiFissi: this.investimentiFormArray[i].controls.varCostiFissi.value,
         })
       }
       for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
         disinvestimentiList.push({
           id: this.disinvestimentiTempId[i],
           idTipoDisinvestimento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value ? this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id : null,
           importo: this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) : 0,
           descrizione: this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value ? this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value : '',
           annoDisinvestimento: this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value,
           meseDisinvestimento: this.disinvestimentiFormArray[i].controls.meseDisinvestimento.value,
           importoCassa: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value && this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 4 && this.disinvestimentiFormArray[i].controls.importoCassaDisinvestimento.value ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCassaDisinvestimento.value) : 0,
 
           importoCostiRetribuzioniPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) : 0,
           importoCostiTfrPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) : 0,
           importoDebitoFornitori: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) : 0,
           importoImmobilizzazioniMateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) : 0,
           importoImmobilizzazioniImmateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) : 0,
           importoValoreMagazzino: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value) : 0,
           importoAmmortamento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoAmmortamento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoAmmortamento.value) : 0,
 
           rimborsoEquity: 0,
         })
       }
       let request: InvestimentiDisinvestimenti = {
         idBusinessPlan: this.richiedente.id,
         investimenti: investimentiList,
         disinvestimenti: disinvestimentiList,
       }
       this.markFormPristine()
       return request
     } else {
       return null
     }
 
   }

   onChangeLatenzaRicavi(i: number, j: number){
    if(this.latenzaRicaviFormArray(i)[j].value){
      if(this.latenzaRicaviFormArray(i)[j].value < 0) this.latenzaRicaviFormArray(i)[j].setValue(-this.latenzaRicaviFormArray(i)[j].value)
      if(this.latenzaRicaviFormArray(i)[j].value > 100) this.latenzaRicaviFormArray(i)[j].setValue(100);
      if(this.latenzaRicaviFormArray(i)[j].value == 100){
for(let k = j+1;k < this.latenzaRicaviFormArray(i).length; k++){
  this.latenzaRicaviFormArray(i)[k].setValue(100);
}
      }
    }
    
  }  
  
  onChangeLatenzaCostiFissi(i: number,j: number){
    if(this.latenzaCostiFissiFormArray(i)[j].value){
      if(this.latenzaCostiFissiFormArray(i)[j].value < 0) this.latenzaCostiFissiFormArray(i)[j].setValue(-this.latenzaCostiFissiFormArray(i)[j].value)
      if(this.latenzaCostiFissiFormArray(i)[j].value > 100) this.latenzaCostiFissiFormArray(i)[j].setValue(100);
      if(this.latenzaCostiFissiFormArray(i)[j].value == 100){
for(let k = j+1;k < this.latenzaCostiFissiFormArray(i).length; k++){
  this.latenzaCostiFissiFormArray(i)[k].setValue(100);
}
      }
    }
    
  }

  onChangeLatenzaCostiVar(i: number, j: number){
    if(this.latenzaCostiVarFormArray(i)[j].value){
      if(this.latenzaCostiVarFormArray(i)[j].value < 0) this.latenzaCostiVarFormArray(i)[j].setValue(-this.latenzaCostiVarFormArray(i)[j].value)
      if(this.latenzaCostiVarFormArray(i)[j].value > 100) this.latenzaCostiVarFormArray(i)[j].setValue(100);
      if(this.latenzaCostiVarFormArray(i)[j].value == 100){
for(let k = j+1;k < this.latenzaCostiVarFormArray(i).length; k++){
  this.latenzaCostiVarFormArray(i)[k].setValue(100);
}
      }
    }
    
  }

  checkTrimestre(i: number){
    this.onChangeTrimestreRicavi(i);
    this.onChangeTrimestreCostiFissi(i);
    this.onChangeTrimestreCostiVar(i);
  }

  onChangeTrimestreRicavi(i: number){
    if(this.investimentiFormArray[i].controls.trimestreBeneficioRicavi.value && this.investimentiFormArray[i].controls.annoBeneficioRicavi.value
      && this.investimentiFormArray[i].controls.trimestreInvestimento.value && this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.annoBeneficioRicavi.value == this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.trimestreBeneficioRicavi.value.id < this.investimentiFormArray[i].controls.trimestreInvestimento.value.id){
        this.investimentiFormArray[i].controls.trimestreBeneficioRicavi.setValue(this.investimentiFormArray[i].controls.trimestreInvestimento.value);
    }
  }

  onChangeTrimestreCostiFissi(i: number){
    if(this.investimentiFormArray[i].controls.trimestreBeneficioCostiFissi.value && this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value
      && this.investimentiFormArray[i].controls.trimestreInvestimento.value && this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.annoBeneficioCostiFissi.value == this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.trimestreBeneficioCostiFissi.value.id < this.investimentiFormArray[i].controls.trimestreInvestimento.value.id){
        this.investimentiFormArray[i].controls.trimestreBeneficioCostiFissi.setValue(this.investimentiFormArray[i].controls.trimestreInvestimento.value);
    }
  }

  onChangeTrimestreCostiVar(i: number){
    if(this.investimentiFormArray[i].controls.trimestreBeneficioCostiVar.value && this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value
      && this.investimentiFormArray[i].controls.trimestreInvestimento.value && this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.annoBeneficioCostiVar.value == this.investimentiFormArray[i].controls.annoInvestimento.value
      && this.investimentiFormArray[i].controls.trimestreBeneficioCostiVar.value.id < this.investimentiFormArray[i].controls.trimestreInvestimento.value.id){
        this.investimentiFormArray[i].controls.trimestreBeneficioCostiVar.setValue(this.investimentiFormArray[i].controls.trimestreInvestimento.value);
    }
  }
 
   riordinaArray(arr: any[]): any[] {
     arr.sort((a, b) => a.annoInvestimento - b.annoInvestimento)
     return arr;
   }
   ngOnDestroy() {
     if (this.richiedenteSmartSubscription != undefined) {
       this.richiedenteSmartSubscription.unsubscribe()
     }
     if(this.inizioDurataSubscription != undefined){
       this.inizioDurataSubscription.unsubscribe();
     }
   }


}
