import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SituazioneCreditizia, SituazioneDebitoria } from '../interface/situazione-debitoria';
import { Richiedente } from '../interface/richiedente';

@Injectable({
  providedIn: 'root'
})
export class SmartBpService {
  private richiedenteSmartBp = new BehaviorSubject<any>(null);
  currentRichiedenteSmartBp: Observable<any> = this.richiedenteSmartBp.asObservable();
  constructor(private httpClient: HttpClient,) { }

  getListaRichiedenti(idUtente: number, anno: number) {
    return this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/getListaRichiedentiSmartBp', { idUtente: idUtente, anno: anno });
  }

  
  getSituazioneCreditiziaSmart(idRichiedente: number) {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/getSituazioneCreditiziaSmart', { idRichiedente: idRichiedente });
  }
  
  getSituazioneDebitoriaSmart(idRichiedente: number) {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/getSituazioneDebitoriaSmart', { idRichiedente: idRichiedente });
  }

  saveSituazioneDebitoriaSmart(situazioneDebitoria: SituazioneDebitoria) {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/saveSituazioneDebitoriaSmart', situazioneDebitoria)
  }

  saveSituazioneCreditiziaSmart(situazioneCreditizia: SituazioneCreditizia) {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/saveSituazioneCreditiziaSmart', situazioneCreditizia)
  }

  elaboraSmartBp(idRichiedente: number, anno: number, investimenti: any[]) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/elaboraSmartBP', { idRichiedente: idRichiedente, anno: anno, investimenti: investimenti });
  }

  inviaRichiedenteSmartBp(richiedenteSmartBp: any) {
    this.richiedenteSmartBp.next(richiedenteSmartBp);
  }

  cancellaTutto() {
    this.richiedenteSmartBp.next(null);
  }

}
