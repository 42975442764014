<div class="container-fluid first-conteiner-top-section">
    <div class="row" style="justify-content: center;
    align-items: center;
    align-content: center;">
        <div class="col-sm-12">
           
                <div class="row contex-data" style="justify-content: center;
                align-items: start;
                align-content: start;">
                    <div class="col-7">

                                        <label style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">ASSUNZIONI</label>
                                        <textarea class="form-control" rows="7"
                                            cols="8" style="resize: none;width: 100%;
                                            line-height: 1.1;padding-top: 2px;"[(ngModel)]="commentoExp" [disabled]="true">
                                    </textarea>

                    </div>
                    <div class="col-1"></div>
                    <div class="col-4">
                        <div style="font-weight: bold;font-size: 15px;text-align: center;
                                        font-family: var(--UI-font);">
                            VALUTAZIONE GENERALE
                        </div>
                        <table mat-table [dataSource]="dataSourcePerditaSortino"
                            class="mat-elevation-z8 mx-auto">

                            <!--- Note that these columns can be defined in any order.
                                                                    The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="Quadro sintetico">
                                <th mat-header-cell *matHeaderCellDef> Quadro Sintetico</th>
                                <td mat-cell *matCellDef="let element"> {{element.tipoQuadroSintetico }} </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="YTD">
                                <th mat-header-cell *matHeaderCellDef>{{this.annoInizioBilancio > 0 ? 'YTD' : ''}} </th>
                                <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? element.ytd !=
                                    'NaN' ? element.ytd : 'N/A':
                                    ''}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="+1YD">
                                <th mat-header-cell *matHeaderCellDef>+1YTD </th>
                                <td mat-cell *matCellDef="let element"> {{element.plusone_yd != 'NaN' ?
                                    element.plusone_yd : 'N/A' }}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="MT">
                                <th mat-header-cell *matHeaderCellDef>MT </th>
                                <td mat-cell *matCellDef="let element"> {{element.mt != 'NaN' ? element.mt : 'N/A'}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="LT">
                                <th mat-header-cell *matHeaderCellDef>LT </th>
                                <td mat-cell *matCellDef="let element"> {{element.lt != 'NaN' ? element.lt : 'N/A' }}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
        </div>
    </div>
</div>


<div class="container-fluid contenitore-sezioni-sintesi" style="margin-top: 30px">

    <div class="row container-sintesi justify-content-center">

        <!-- SINTESI SINISTRA AS IS ... START-->
        <div class="col-3 d-flex  " id="left-section-sintesi">
            <app-sintesi-as-is [is_as]="is_as"></app-sintesi-as-is>
        </div>
        <!-- SINTESI SINISTRA AS IS... END-->

        <!-- SINTESI CENTRO CORSA FINALE ... START-->
        <div class="col-8 d-flex" id="center-section-sintesi" [@zoomChartDettaglioState]="stateZoomInverse">
            <app-sintesi-end-stroke *ngIf="is_as; else year">
            </app-sintesi-end-stroke>

            <ng-template #year>
                <app-sintesi-precisely-year [select_year]="this.select_year">
                </app-sintesi-precisely-year>
            </ng-template>
        </div>
        <!-- SINTESI CENTRO CORSA FINALE ... END-->
        <!-- SINTESI DESTRA GRAFICI SINTESI ... START-->
        <!-- <div class="col-3 d-flex justify-content-start " id="right-section-sintesi"
            [@zoomChartDettaglioState]="stateZoomInverse">
            <app-sintesi-graph-group></app-sintesi-graph-group>
        </div>-->
        <!-- SINTESI DESTRA GRAFICI SINTESI ... END-->

        <!-- GRAPH DETTAGLI SPAWN WITH TRIGER ANIMATION ... START-->
        <!--  <div class="container-fluid" [@zoomChartDettaglioState]="stateZoom">
            <div class="row" style="justify-content: center;
                    align-items: center;
                    align-content: center;">
                <div class="col-9 col-zoom-dettagli-graph">
                    <div class="row">
                        <div class="col-12 inner-graph-zoom" style=" background-color: var(--soft-grey);
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        margin-top: -10px;
                        margin-left: -10px;
                        border-radius: 20px;">
                            <mat-icon class="unzoom-button-trigger" (click)="unZoomClick()">fullscreen_exit</mat-icon>
                            <app-line-chart [valueOrdinataNegativo]="valueOrdinataNegativo"
                                [valueOrdinataMedia]="valueOrdinataMedia"
                                [valueOrdinataPositivo]="valueOrdinataPositivo" [labelAscissa]="">
                            </app-line-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        <!-- GRAPH DETTAGLI SPAWN WITH TRIGER ANIMATION ... END-->
    </div>



</div>