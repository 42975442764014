import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';
import { Provincia } from '../condivisi/provincia';
import { LoadingService } from '../loading.service';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { TokenStorageService } from '../_services/token-storage.service';
import { Richiedente } from 'src/app/interface/richiedente';
import { ClienteService } from '../_services/cliente.service';
import { RichiedenteService } from 'src/app/_services/richiedente.service';
import { faCaretUp, faCaretDown, faInfo, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { ToolTipService } from './../_services/tool-tip.service';
import { ToolTip } from '../interface/toolTip';
import { Subscription } from 'rxjs';


/* QUESTIONI APERTE */
/* inserte cliente già presente a db: può avvebure solo se l'azienda ha un contratto scaduto con endy. In questa situazione
 o rompo il legame richiedente/cliente e ne creo uno nuovo con il commercialista oppure il legame (pivot) è già stato rotto alla scadenza del contratto*/
@Component({
  selector: 'app-censimento-richiedente',
  templateUrl: './censimento-richiedente.component.html',
  styleUrls: ['./censimento-richiedente.component.css']
})
export class CensimentoRichiedenteComponent implements OnInit {
  anagraficaForm: UntypedFormGroup;
  initialValues: any;
  date: Date = new Date();
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  listaAnni: number[];
  currentAnno = new Date().getFullYear()

  /* listaRichiedenti: Richiedente[] = []; */
  mostraAziende = false;
  nomeSalvataggio: string;
  listaProvince: Provincia[] = [];
  listaComuni: BeanStatico[];
  listaTipiAzienda: BeanStatico[] = [];
  listaTips: ToolTip[] = [];

  listaAtecoPrimarioSezione: BeanStatico[] = [];
  listaAtecoPrimarioDivisione: BeanStatico[] = [];
  listaAtecoPrimarioClasse: BeanStatico[] = [];
  listaAtecoPrimarioSottocategoria: BeanStatico[] = [];

  atecoPrimarioTemp: string;
  /* serve per passare il dato al component ateco-secondari */
  atecoSecondariTemp: string[] = [];
  /* serve a passare il dato per la query al component ateco-secondari */
  atecoSecondarioTemp: string;
  /* servono solo per salvare il dato */
  atecoSecondarioTemp1: string = null;
  atecoSecondarioTemp2: string = null;

  addAtecoDisabled = false;
  submitDisabled = false;
  richiedenteTemp: any = null;
  clienteTemp: any = null;
  idRichiedenteTemp = 0;
  numeroPivaUtilizzate: number;
  maxPiva: number;


  tempIdComune: number = -1;
  tempIdDivisione: number = -1;
  tempIdClasse: number = -1;
  tempIdSottocategoria: number = -1;
  submitted = false;
  faInfo = faInfo;

  numeroPivaSub: Subscription


  constructor(
    private httpClient: HttpClient,
    private loading: LoadingService,
    private toolTipService: ToolTipService,
    private clienteService: ClienteService,
    private richiedenteService: RichiedenteService,
    private tokeStorageService: TokenStorageService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal) {

    this.anagraficaForm = this.formBuilder.group({
      nomeAzienda: [''],
      partitaIva: [''],
      tipoAzienda: [''],
      sedeLegale: [''],
      provincia: [''],
      comune: [{ value: '', disabled: true }],
      cap: [''],
      emailRichiedente: [''],
      telefonoRichiedente: [''],
      startUp: [false],
      annoCostituzione: [''],
      atecoPrimarioSezione: [''],
      atecoPrimarioDivisione: [{ value: '', disabled: true }],
      atecoPrimarioClasse: [{ value: '', disabled: true }],
      atecoPrimarioSottocategoria: [{ value: '', disabled: true }],
      atecoSecondariArray: this.formBuilder.array([
      ]),
    })
    this.initialValues = this.anagraficaForm.value;
  }
  get f() { return this.anagraficaForm.controls; }
  get atecoSecondariArray(): UntypedFormArray { return <UntypedFormArray> this.anagraficaForm.controls.atecoSecondariArray; }
  get atecoForm(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.atecoSecondariArray.controls }

  ngOnDestroy() {
    this.numeroPivaSub.unsubscribe()
  }

  ngOnInit(): void {
    this.listaAnni = []
    for (let i = this.currentAnno; i >= 1950; i--) {
      this.listaAnni.push(i);
    }
    this.submitted = false;
    this.toolTipService.getTipsContent('ANA').subscribe(risultato => {
      if (risultato) {
        risultato.forEach(el => {
          this.listaTips.push(el)
        })
        this.listaTips.sort((n1, n2) => n1.numero - n2.numero)
      }
    })
    this.numeroPivaSub = this.clienteService.currentNumeroPivaUtilizzate.subscribe((numeroPivaUtilizzate) => {
      this.numeroPivaUtilizzate = numeroPivaUtilizzate;
    })

    this.clienteService.getNumeroPivaUtilizzate().subscribe((numeroPivaUtilizzate) => {
      this.clienteService.updateNumeroPivaUtilizzate(numeroPivaUtilizzate);

    })
    this.clienteService.getMaxPiva().subscribe((maxPivaTemp) => {
      this.maxPiva = maxPivaTemp
    })

    this.onLoadSelect()
    this.updateValidators()
  }

  checkPartitaIva() {
    /* L'azienda può essere agigunta solo se il cliente non esiste o il cliente esiste ma il contratto è scADUTO */
    if (this.f.partitaIva.valid) {
      this.richiedenteService.getRichiedenteByPiva(this.f.partitaIva.value).subscribe(response => {
        if (response != null) {
          this.richiedenteTemp = null;
          this.clienteTemp = null;
          if (response.datiCliente != null) {
            /* casi in cui è abilitata la modifica: */
            /* 1. azienda gia associata al quel commercialista. risponde picche e di usare il modifica */
            if (response.datiCliente.idCliente == this.tokeStorageService.getUser().cliente.idCliente) {
              if (this.modalService.hasOpenModals) {
                this.modalService.dismissAll();
              }
              const modalRef = this.modalService.open(ModalcontentComponent, {
                backdrop: 'static',
                backdropClass: 'customBackdrop',
              });
              modalRef.componentInstance.modalPar = {
                name: "messaggiorichiedentegiacensito",
                description: "Hai già registrato quest'azienda.Se vuoi modificarne i dati clicca su 'Modifica' nella lista delle azienda registrate",
                actionButtonText: "",
                undoButtonText: "Ok",
              }
              modalRef.result.then(r => {
                this.onReset();
              })
            } else {
              /* nel caso di azienda gia presente ma non registrata con questo cliente, azzero l'id
dell'azienda e la tratto come una nuova.a BE poi tratto i vari casi (azienda nuova / azienda presa da db)*/
              /*2. azienda associata ad un altro cliente controllo la data di scadenza delll'azienda trovata per verificare se può aggiungerla.
            caso azienda con contratto scaduto*/

              const data = new Date(response.datiCliente.scadenzaContratto).getTime()
              this.richiedenteTemp = response.datiRichiedente;
              this.clienteTemp = response.datiCliente;
              let request = {
                partitaIva: this.richiedenteTemp.partitaIva,
                ragioneSociale: this.richiedenteTemp.ragioneSociale,
                sedeLegale: this.richiedenteTemp.sedeLegale,
                cap: this.richiedenteTemp.cap,
                comune: this.richiedenteTemp.comune.descrizione,
                provincia: this.richiedenteTemp.provincia.sigla,
              }
              /* caso in cui posso inserire l'azienda  se data scadenza contratto>data attuale*/
              if (data <= this.date.getTime()) {

                if (this.modalService.hasOpenModals) {
                  this.modalService.dismissAll();
                }
                const modalRef = this.modalService.open(ModalcontentComponent, {
                  backdrop: 'static',
                  backdropClass: 'customBackdrop',
                });
                modalRef.componentInstance.modalPar = {
                  name: "checkdatarichiedente",
                  description: request,
                  actionButtonText: "Registra",
                  undoButtonText: "Annulla",
                  question: "Desideri registrare quest'azienda?"
                }
                modalRef.result.then(r => {
                  if (r) {
                    /* nel caso di azienda gia presente ma non registrata con questo cliente, azzero l'id
                    dell'azienda e la tratto come una nuova.a BE poi tratto i vari casi (azienda nuova / azienda presa da db)*/
                    this.richiedenteTemp.id = 0;
                    /* costruzione del form ateco secondari se l'azienda ce  l'ha. un form per ogni ATECO secondario (max 2)*/
                    this.atecoSecondariArray.clear();
                    this.atecoSecondariTemp = [];
                    /* carico gli ateco secondairi su un vettore */
                    if (this.richiedenteTemp.codiceAtecoSecondario1 != null) {
                      this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario1)
                    } if (this.richiedenteTemp.codiceAtecoSecondario2 != null) {
                      this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario2)
                    }
                    for (let index = 0; index < this.atecoSecondariTemp.length; index++) {
                      this.addAteco();
                    }
                    /* chiamata per prendere gli id del codice ateco primario*/
                    this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/codiciAteco', this.richiedenteTemp.atecoPrimario).subscribe(response => {
                      this.tempIdDivisione = response.idDivisione;
                      this.tempIdClasse = response.idClasse;
                      this.tempIdSottocategoria = response.idSottocategoria;
                      this.listaAtecoPrimarioSezione.forEach(atecoSezione => {
                        if (atecoSezione.id == response.idSezione) {
                          this.f.atecoPrimarioSezione.setValue(atecoSezione);
                        }
                      })
                    })
                    this.tempIdComune = this.richiedenteTemp.comune.id;
                    this.listaTipiAzienda.forEach(tipoAzienda => {
                      if (tipoAzienda.id == this.richiedenteTemp.tipoAzienda.id) {
                        this.f.tipoAzienda.disable();
                        this.f.tipoAzienda.setValue(tipoAzienda);
                      }
                    })

                    this.listaProvince.forEach(provincia => {
                      if (provincia.id == this.richiedenteTemp.provincia.id) {
                        this.f.provincia.setValue(provincia);
                      }
                    })
                    this.f.partitaIva.setValue(this.richiedenteTemp.partitaIva);
                    this.f.partitaIva.disable();
                    this.f.nomeAzienda.setValue(this.richiedenteTemp.ragioneSociale);
                    this.f.nomeAzienda.disable()
                    this.f.sedeLegale.setValue(this.richiedenteTemp.sedeLegale);
                    this.f.cap.setValue(this.richiedenteTemp.cap);
                    this.f.emailRichiedente.setValue(this.richiedenteTemp.email);
                    this.f.telefonoRichiedente.setValue(this.richiedenteTemp.telefono);
                    this.f.startUp.setValue(this.richiedenteTemp.isStartUp);
                    this.f.annoCostituzione.setValue(this.richiedenteTemp.annoCostituzione)

                    /* setvalue */

                    this.f.sedeLegale.updateValueAndValidity();
                    this.f.emailRichiedente.updateValueAndValidity();
                    this.f.telefonoRichiedente.updateValueAndValidity();
                    this.f.cap.updateValueAndValidity();
                    this.f.partitaIva.updateValueAndValidity();
                    this.f.tipoAzienda.updateValueAndValidity();
                    this.f.nomeAzienda.updateValueAndValidity();
                    this.f.provincia.updateValueAndValidity();
                    this.f.atecoPrimarioSezione.updateValueAndValidity();
                    this.f.atecoPrimarioDivisione.updateValueAndValidity();
                    this.f.atecoPrimarioClasse.updateValueAndValidity();
                    this.f.atecoPrimarioSottocategoria.updateValueAndValidity();
                    this.f.startUp.updateValueAndValidity();
                    this.f.annoCostituzione.updateValueAndValidity()
                  }
                })
                /* caso azienda con contratto non ancora scaduto */
              } else if (data > this.date.getTime()) {
                if (this.modalService.hasOpenModals) {
                  this.modalService.dismissAll();
                }
                const modalRef = this.modalService.open(ModalcontentComponent, {
                  backdrop: 'static',
                  backdropClass: 'customBackdrop',
                });
                modalRef.componentInstance.modalPar = {
                  name: "errorecheckdata",
                  description: request,
                  question: "Non è possibile registrare quest'azienda in quanto è già nostra cliente. Per ulteriori informazioni puoi contattare l'amministrazione all'indirizzo:",
                  undoButtonText: "Ok",
                }
                modalRef.result.then(r => {
                  if (!r) {
                    this.f.partitaIva.setValue('')
                    this.f.partitaIva.enable();
                    this.f.partitaIva.updateValueAndValidity();
                  }
                })
              }
            }
            /* questa parte viene chiamata solo se l'azienda non ha un cliente cioè è stata censita direttamente da registro imprese */
          } else if (response.datiCliente == null) {
            this.richiedenteTemp = response.datiRichiedente;
            this.clienteTemp == null;
            let request = {
              partitaIva: this.richiedenteTemp.partitaIva,
              ragioneSociale: this.richiedenteTemp.ragioneSociale,
              sedeLegale: this.richiedenteTemp.sedeLegale,
              cap: this.richiedenteTemp.cap,
              comune: this.richiedenteTemp.comune.descrizione,
              provincia: this.richiedenteTemp.provincia.sigla,
            }

            if (this.modalService.hasOpenModals) {
              this.modalService.dismissAll();
            }
            const modalRef = this.modalService.open(ModalcontentComponent, {
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar = {
              name: "checkdatarichiedente",
              description: request,
              actionButtonText: "Registra",
              undoButtonText: "Annulla",
              question: "Desideri registrare quest'azienda?"
            }
            modalRef.result.then(r => {
              if (r) {
                /* nel caso di azienda gia presente ma non registrata con questo cliente, azzero l'id
dell'azienda e la tratto come una nuova.a BE poi tratto i vari casi (azienda nuova / azienda presa da db)*/
                this.richiedenteTemp.id = 0;
                /* costruzione del form ateco secondari se l'azienda ce  l'ha. un form per ogni ATECO secondario (max 2)*/
                this.atecoSecondariArray.clear();
                this.atecoSecondariTemp = [];
                /* carico gli ateco secondairi su un vettore */
                if (this.richiedenteTemp.codiceAtecoSecondario1 != null) {
                  this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario1)
                } if (this.richiedenteTemp.codiceAtecoSecondario2 != null) {
                  this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario2)
                }
                for (let index = 0; index < this.atecoSecondariTemp.length; index++) {
                  this.addAteco();
                }
                /* chiamata per prendere gli id del codice ateco primario*/
                this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/codiciAteco', this.richiedenteTemp.atecoPrimario).subscribe(response => {
                  this.tempIdDivisione = response.idDivisione;
                  this.tempIdClasse = response.idClasse;
                  this.tempIdSottocategoria = response.idSottocategoria;
                  this.listaAtecoPrimarioSezione.forEach(atecoSezione => {
                    if (atecoSezione.id == response.idSezione) {
                      this.f.atecoPrimarioSezione.setValue(atecoSezione);
                    }
                  })
                })
                this.tempIdComune = this.richiedenteTemp.comune.id;
                this.listaTipiAzienda.forEach(tipoAzienda => {
                  if (tipoAzienda.id == this.richiedenteTemp.tipoAzienda.id) {
                    this.f.tipoAzienda.disable();
                    this.f.tipoAzienda.setValue(tipoAzienda);
                  }
                })

                this.listaProvince.forEach(provincia => {
                  if (provincia.id == this.richiedenteTemp.provincia.id) {
                    this.f.provincia.setValue(provincia);
                  }
                })
                this.f.partitaIva.setValue(this.richiedenteTemp.partitaIva);
                this.f.partitaIva.disable();
                this.f.nomeAzienda.setValue(this.richiedenteTemp.ragioneSociale);
                this.f.nomeAzienda.disable()
                this.f.sedeLegale.setValue(this.richiedenteTemp.sedeLegale);
                this.f.cap.setValue(this.richiedenteTemp.cap);
                this.f.emailRichiedente.setValue(this.richiedenteTemp.email);
                this.f.telefonoRichiedente.setValue(this.richiedenteTemp.telefono);
                this.f.startUp.setValue(this.richiedenteTemp.isStartUp)
                this.f.annoCostituzione.setValue(this.richiedenteTemp.annoCostituzione)
                /* setvalue */

                this.f.sedeLegale.updateValueAndValidity();
                this.f.emailRichiedente.updateValueAndValidity();
                this.f.telefonoRichiedente.updateValueAndValidity();
                this.f.cap.updateValueAndValidity();
                this.f.partitaIva.updateValueAndValidity();
                this.f.tipoAzienda.updateValueAndValidity();
                this.f.nomeAzienda.updateValueAndValidity();
                this.f.provincia.updateValueAndValidity();
                this.f.atecoPrimarioSezione.updateValueAndValidity();
                this.f.atecoPrimarioDivisione.updateValueAndValidity();
                this.f.atecoPrimarioClasse.updateValueAndValidity();
                this.f.atecoPrimarioSottocategoria.updateValueAndValidity();
                this.f.startUp.updateValueAndValidity();
                this.f.annoCostituzione.updateValueAndValidity()
              }
            })
          }
        }
      })
    }
  }
  onLoadSelect() {
    //tipo azienda, ragione sociale
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
    });

    //province
    this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince').subscribe(data => {
      this.listaProvince = data;
    })
    //comuni
    this.f.provincia.valueChanges.subscribe(selectedValue => {
      this.listaComuni = [];
      if (this.tempIdComune == -1) {
        this.f.comune.setValue('');
      }
      if (selectedValue) {
        this.f.comune.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const elementoLista = { id: element.id, descrizione: element.comune };
            //console.log(elementoLista)
            this.listaComuni.push(elementoLista)
            if (element.id == this.tempIdComune) {
              this.f.comune.setValue(elementoLista);
              //this.f.comune.disable();
              this.tempIdComune = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.comune.disable();
      }
      this.f.comune.updateValueAndValidity();
    })
    /* ateco Sezione*/
    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSezioni').subscribe(data => {

      this.listaAtecoPrimarioSezione = [];
      data.forEach(element => {
        let atecoSezione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoPrimarioSezione.push(atecoSezione)
      });
    });

    /* ateco divisione */
    this.f.atecoPrimarioSezione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioDivisione = [];
      if (this.tempIdDivisione == -1) {
        this.f.atecoPrimarioDivisione.setValue('');
      } 
      if (selectedValue) {
        this.f.atecoPrimarioDivisione.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioniByIdSezione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoDivisione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioDivisione.push(atecoDivisione)
            if (element.id == this.tempIdDivisione) {
              this.f.atecoPrimarioDivisione.setValue(atecoDivisione);
              //this.f.comune.disable();
              this.tempIdDivisione = -1;
            }
          });
        });
      }
      else {
        this.f.atecoPrimarioDivisione.disable();
      }
      this.f.atecoPrimarioDivisione.updateValueAndValidity();
    })
    /* ateco classe */
    this.f.atecoPrimarioDivisione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioClasse = [];
      if (this.tempIdClasse == -1) {
        this.f.atecoPrimarioClasse.setValue('');
      }
      if (selectedValue) {
        this.f.atecoPrimarioClasse.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdDivisione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoClasse = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioClasse.push(atecoClasse)
            if (element.id == this.tempIdClasse) {
              this.f.atecoPrimarioClasse.setValue(atecoClasse);
              //this.f.comune.disable();
              this.tempIdClasse = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoPrimarioClasse.disable();
      }
      this.f.atecoPrimarioClasse.updateValueAndValidity();
    })
    /* ateco sottocategoria */
    this.f.atecoPrimarioClasse.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioSottocategoria = [];
      if (this.tempIdSottocategoria == -1) {
        this.f.atecoPrimarioSottocategoria.setValue('');
      }
      if (selectedValue) {
        this.f.atecoPrimarioSottocategoria.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSottocategorieByIdClasse', selectedValue.id).subscribe(data => {
          if (data.length == 0) {
            this.listaAtecoPrimarioSottocategoria.push({ id: 0, descrizione: 'N/A' })
          }
          data.forEach(element => {
            const atecoSottocategoria = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioSottocategoria.push(atecoSottocategoria)
            if (element.id == this.tempIdSottocategoria) {
              this.f.atecoPrimarioSottocategoria.setValue(atecoSottocategoria);
              //this.f.comune.disable();
              this.tempIdSottocategoria = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoPrimarioSottocategoria.disable();
      }
      this.f.atecoPrimarioSottocategoria.updateValueAndValidity();
    })
  }
  updateValidators() {
    this.f.partitaIva.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
    this.f.tipoAzienda.setValidators(Validators.required);
    this.f.nomeAzienda.setValidators(Validators.required);
    this.f.provincia.setValidators(Validators.required);
    this.f.comune.setValidators(Validators.required);
    this.f.sedeLegale.setValidators(Validators.required);
    this.f.cap.setValidators(Validators.required);
    this.f.emailRichiedente.setValidators([Validators.required, Validators.email]);
    this.f.telefonoRichiedente.setValidators(Validators.required);
    this.f.atecoPrimarioSezione.setValidators(Validators.required);
    this.f.atecoPrimarioDivisione.setValidators(Validators.required);
    this.f.atecoPrimarioClasse.setValidators(Validators.required);
    this.f.annoCostituzione.setValidators(Validators.required)
    /* this.f.atecoPrimarioSottocategoria.setValidators(Validators.required); */

    /* validatori di tutti i campi */

    this.f.sedeLegale.updateValueAndValidity();
    this.f.emailRichiedente.updateValueAndValidity();
    this.f.telefonoRichiedente.updateValueAndValidity();
    this.f.cap.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity();
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();
    this.f.provincia.updateValueAndValidity();
    this.f.comune.updateValueAndValidity();
    this.f.startUp.updateValueAndValidity();
    this.f.annoCostituzione.updateValueAndValidity()
    this.f.atecoPrimarioSezione.updateValueAndValidity();
    this.f.atecoPrimarioDivisione.updateValueAndValidity();
    this.f.atecoPrimarioClasse.updateValueAndValidity();
    /* this.f.atecoPrimarioSottocategoria.updateValueAndValidity(); */

  }
  mostraLista(mostraAziende: boolean) {
    this.mostraAziende = mostraAziende;
  }

  /* dal tasto modifica nella lista */
  modificaRichiedente(richiedente: Richiedente) {
    /* setto il val a '' altrimenti i valuechange potrebbero non funzionare se l'input non cambia tra un modifica e l'altro */
    this.f.atecoPrimarioSezione.setValue('');
    this.f.atecoPrimarioDivisione.setValue('');
    this.f.atecoPrimarioClasse.setValue('');
    this.f.atecoPrimarioSottocategoria.setValue('');
    this.f.atecoPrimarioSezione.updateValueAndValidity();
    this.f.atecoPrimarioDivisione.updateValueAndValidity();
    this.f.atecoPrimarioClasse.updateValueAndValidity();
    this.f.atecoPrimarioSottocategoria.updateValueAndValidity();

    this.richiedenteTemp = null;
    /* metto element dentro richiedenteTemp per non rompere il salvataggio e per assegnare i campi in un ciclo*/
    this.richiedenteTemp = richiedente;

    /* passo la stringa del codice ateco a BE per prendere gli id di classe */
    if (this.richiedenteTemp.codiceAtecoPrimario != null) {
      this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/codiciAteco', richiedente.codiceAtecoPrimario).subscribe(response => {
        this.tempIdDivisione = response.idDivisione;
        this.tempIdClasse = response.idClasse;
        this.tempIdSottocategoria = response.idSottocategoria;
        this.listaAtecoPrimarioSezione.forEach(atecoSezione => {
          if (atecoSezione.id == response.idSezione) {
            this.f.atecoPrimarioSezione.setValue(atecoSezione);
          }
        })
      })
    }
    /* costruzione del form ateco secondari se l'azienda ce  l'ha. un form per ogni ATECO secondario (max 2)*/
    this.atecoSecondariArray.clear();
    this.atecoSecondariTemp = [];
    /* carico gli ateco secondairi su un vettore */
    if (this.richiedenteTemp.codiceAtecoSecondario1 != null) {
      this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario1)
    } if (this.richiedenteTemp.codiceAtecoSecondario2 != null) {
      this.atecoSecondariTemp.push(this.richiedenteTemp.codiceAtecoSecondario2)
    }
    for (let index = 0; index < this.atecoSecondariTemp.length; index++) {
      this.addAteco();
    }

    this.f.partitaIva.setValue(this.richiedenteTemp.partitaIva);
    this.f.partitaIva.disable();
    this.f.nomeAzienda.setValue(this.richiedenteTemp.ragioneSociale);
    this.f.nomeAzienda.disable();
    this.f.sedeLegale.setValue(this.richiedenteTemp.sedeLegale);
    this.f.cap.setValue(this.richiedenteTemp.cap);
    this.f.emailRichiedente.setValue(this.richiedenteTemp.email);
    this.f.telefonoRichiedente.setValue(this.richiedenteTemp.telefono);
    this.f.startUp.setValue(this.richiedenteTemp.isStartUp);
    this.f.annoCostituzione.setValue(this.richiedenteTemp.annoCostituzione)

    this.tempIdComune = this.richiedenteTemp.idComune;

    this.listaTipiAzienda.forEach(tipoAzienda => {
      if (tipoAzienda.id == this.richiedenteTemp.idFormaGiuridica) {
        this.f.tipoAzienda.disable();
        this.f.tipoAzienda.setValue(tipoAzienda);
      }
    })
    this.listaProvince.forEach(provincia => {
      if (provincia.id == this.richiedenteTemp.idProvincia) {
        this.f.provincia.setValue(provincia);
      }
    })
  }

  eliminaAteco(idAteco: number) {
    this.atecoSecondariArray.removeAt(idAteco);
    if (this.atecoSecondariArray.length == 1) {
      this.atecoSecondarioTemp2 = null;
    } else if (this.atecoSecondariArray.length == 0) {
      this.atecoSecondarioTemp1 = null;
    }

  }

  addAteco() {
    if (this.atecoSecondariArray.length < 2) {
      const sottoForm = this.formBuilder.group({
        atecoSecondarioSezione: ['', Validators.required],
        atecoSecondarioDivisione: [{ value: '', disabled: true }, Validators.required],
        atecoSecondarioClasse: [{ value: '', disabled: true }, Validators.required],
        atecoSecondarioSottocategoria: [{ value: '', disabled: true }],

      })
      sottoForm.controls.atecoSecondarioSezione.updateValueAndValidity();
      sottoForm.controls.atecoSecondarioDivisione.updateValueAndValidity();
      sottoForm.controls.atecoSecondarioSottocategoria.updateValueAndValidity();

      this.atecoSecondariArray.push(sottoForm);
    } else {
      this.addAtecoDisabled = true;
    }

  }

  onReset() {
    this.anagraficaForm.reset(this.initialValues);
    this.submitted = false;
    this.submitDisabled = false;
    this.richiedenteTemp = null;
    this.f.partitaIva.enable();
    this.f.tipoAzienda.enable();
    this.f.nomeAzienda.enable();

    this.f.partitaIva.updateValueAndValidity();
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();

    this.addAtecoDisabled = false;
    this.atecoSecondariArray.clear();
    this.atecoSecondarioTemp1 = null;
    this.atecoSecondarioTemp2 = null;

  }
  submit() {
    this.nomeSalvataggio = '';
    if (!this.anagraficaForm.valid) {
      this.submitted = true;
    } else if (this.anagraficaForm.valid) {
      this.submitted = false;
      if (this.numeroPivaUtilizzate >= this.maxPiva) {
        if (this.modalService.hasOpenModals) {
          this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
        }
        this.submitDisabled = true;
        /* modale che dirà che ha esaurito le piva */
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          title: "Azienda non registrata!",
          name: "errorecensimentorichiedente",
          description: "Hai raggiunto il numero massimo di aziende disponibili su questo account. Fai un upgrade dell'account per aumentare il numero di slot disponibili oppure elimina una delle azienda registrate su cui non sono stati sviluppati Business Plan.",
          actionButtonText: "Upgrade account",
          undoButtonText: "Annulla",

        }
      } else {
        if (this.modalService.hasOpenModals) {
          this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
        }
        this.nomeSalvataggio = this.f.nomeAzienda.value + " - " + this.f.partitaIva.value

        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          name: "salva",
          description: this.nomeSalvataggio,
          title: "Salvataggio azienda osservata",
          actionButtonText: "Salva",
          undoButtonText: "Indietro",
        }
        modalRef.result.then(r => {
          if (r.promise) {
            this.nomeSalvataggio == r.nomesalvataggio;
            if (this.nomeSalvataggio != '') {
              this.salvaAnagraficaRichiedente();
            }
          }
        })
      }

    }
  }
  salvaAnagraficaRichiedente() {
    /* invio il codice corretto. non è detto che compila tutto */
    /* gli carico la sottocategoria di dafault e vado a scalare. codice ateco primario obbligaorio fino alla classe */

    if (this.f.atecoPrimarioSottocategoria.value != "" && this.f.atecoPrimarioSottocategoria.value.descrizione != "N/A") {
      this.atecoPrimarioTemp = this.f.atecoPrimarioSottocategoria.value.descrizione.substring(0, 8);
      console.log(this.atecoPrimarioTemp)
    } else {
      this.atecoPrimarioTemp = this.f.atecoPrimarioClasse.value.descrizione.substring(0, 5).trim();;
    }
    /* codici ateco secondari */
    if (this.atecoSecondariArray.length > 0) {
      /*Ateco secondario-1 */
      if (this.atecoForm[0].controls.atecoSecondarioSottocategoria.value != "" && this.atecoForm[0].controls.atecoSecondarioSottocategoria.value.descrizione != "N/A") {
        this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioSottocategoria.value.descrizione.substring(0, 8);
      } else {
        if (this.atecoForm[0].controls.atecoSecondarioClasse.value != "") {
          this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioClasse.value.descrizione.substring(0, 5).trim();
        } else {
          this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioDivisione.value.descrizione.substring(0, 2);
        }

      }
      /*Ateco secondario-2 */
      if (this.atecoSecondariArray.length > 1) {
        if (this.atecoForm[1].controls.atecoSecondarioSottocategoria.value != "" && this.atecoForm[1].controls.atecoSecondarioSottocategoria.value.descrizione != "N/A") {
          this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioSottocategoria.value.descrizione.substring(0, 8);
        } else {
          if (this.atecoForm[1].controls.atecoSecondarioClasse.value != '') {
            this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioClasse.value.descrizione.substring(0, 5).trim();;
          } else {
            this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioDivisione.value.descrizione.substring(0, 2);
          }
        }
      }
    }


    let richiedente: Richiedente = {
      id: this.richiedenteTemp == null ? 0 : this.richiedenteTemp.id,
      partitaIva: this.f.partitaIva.value,
      ragioneSociale: this.f.nomeAzienda.value,
      codiceFiscale: this.richiedenteTemp == null ? null : this.richiedenteTemp.codiceFiscale, /* modificare */
      idFormaGiuridica: this.f.tipoAzienda.value.id,
      sedeLegale: this.f.sedeLegale.value,
      idRegione: this.f.provincia.value.idRegione,
      idProvincia: this.f.provincia.value.id,
      idComune: this.f.comune.value.id,
      cap: this.f.cap.value,
      idInfoAggiuntive: this.richiedenteTemp == null ? null : this.richiedenteTemp.idInfoAggiuntive,
      codiceAtecoPrimario: this.atecoPrimarioTemp,
      codiceAtecoSecondario1: this.atecoSecondarioTemp1,
      codiceAtecoSecondario2: this.atecoSecondarioTemp2,
      telefono: this.f.telefonoRichiedente.value,
      email: this.f.emailRichiedente.value,
      isStartUp: this.f.startUp.value,
      dataCreazione: this.richiedenteTemp == null ? this.date.getTime() : this.richiedenteTemp.dataCreazione,
      annoCostituzione: this.f.annoCostituzione.value
    }
    let request = {
      idCliente: this.tokeStorageService.getUser().cliente.idCliente,
      numeroPivaUtilizzate: richiedente.id != 0 ? this.numeroPivaUtilizzate : (this.numeroPivaUtilizzate + 1), // gestisco il caso nuovo/modifica richiedente
      richiedente: richiedente
    }
    this.richiedenteService.saveAnagraficaRichiedente(request).subscribe((response) => {
      // chiudo la lista per farla aggiornare 
      this.mostraAziende = false;
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "messaggiosalva",
        description: response.response,
        actionButtonText: "",
        undoButtonText: "Ok",
      }
      modalRef.result.then((r) => {
        // chiamata per aggiornare il numero delle p iva usate
        this.clienteService.updateNumeroPivaUtilizzate(request.numeroPivaUtilizzate);
        this.onReset();

      })
    })
  }



}// fine classe
